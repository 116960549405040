import React from "react";
import "./css/intro-new.css";
import GHD from "./assets/landing/GHD.avif";
import benefit from "./assets/landing/benefit.avif";
import benefit2x from "./assets/landing/benefit@2x.avif";
import farmacy from "./assets/landing/farmacy.avif";
import bigSquare from "./assets/landing/big_square.avif";
import bigSquare2x from "./assets/landing/big_square@2x.avif";
import birchbox from "./assets/landing/birchbox.avif";
import birchbox2x from "./assets/landing/birchbox@2x.avif";
import carrie from "./assets/landing/carrie.avif";
import cetaphil from "./assets/landing/cetaphil.avif";
import converse from "./assets/landing/converse.avif";
import converse2x from "./assets/landing/converse@2x.avif";
import dyson from "./assets/landing/dyson.avif";
import dyson2x from "./assets/landing/dyson@2x.avif";
import ePc from "./assets/landing/e_pc.avif";
import ePc2x from "./assets/landing/e_pc@2x.avif";
import elemis from "./assets/landing/elemis.avif";
import elemis2x from "./assets/landing/elemis@2x.avif";
import harrys from "./assets/landing/harrys.avif";
import harrys2x from "./assets/landing/harrys@2x.avif";
import ibm from "./assets/landing/ibm.avif";
import ibm2x from "./assets/landing/ibm@2x.avif";
import ikea from "./assets/landing/ikea.avif";
import ikea2x from "./assets/landing/ikea@2x.avif";
import jakeAndCoke from "./assets/landing/jake_and_coke.avif";
import jetblueBermuda from "./assets/landing/jetblue_bermuda.avif";
import kidneyIsland from "./assets/landing/kidney_island.avif";
import knorr from "./assets/landing/knorr.avif";
import knorr2x from "./assets/landing/knorr@2x.avif";
import lindt from "./assets/landing/lindt.avif";
import lindt2x from "./assets/landing/lindt@2x.avif";
import lush from "./assets/landing/lush.avif";
import lush2x from "./assets/landing/lush@2x.avif";
import makersMark from "./assets/landing/makers_mark.avif";
import makersMark2x from "./assets/landing/makers_mark@2x.avif";
import narconHouse from "./assets/landing/narcon_house.avif";
import narconHouse2x from "./assets/landing/narcon_house@2x.avif";
import nike from "./assets/landing/nike.avif";
import nike2x from "./assets/landing/nike@2x.avif";
import ole from "./assets/landing/ole.avif";
import ole2x from "./assets/landing/ole@2x.avif";
import ollyGirlsroom from "./assets/landing/olly_girlsroom.avif";
import ollyGirlsroom2x from "./assets/landing/olly_girlsroom@2x.avif";
import peloton from "./assets/landing/peloton.avif";
import peloton2x from "./assets/landing/peloton@2x.avif";
import plated from "./assets/landing/plated.avif";
import plated2x from "./assets/landing/plated@2x.avif";
import pringles from "./assets/landing/pringles.avif";
import pringles2x from "./assets/landing/pringles@2x.avif";
import proactiv from "./assets/landing/proactiv.avif";
import proactiv2x from "./assets/landing/proactiv@2x.avif";
import ralphLaurenAvicii from "./assets/landing/ralph_lauren_avicii.avif";
import ralphLaurenAvicii2x from "./assets/landing/ralph_lauren_avicii@2x.avif";
import ralphLauren from "./assets/landing/ralph_lauren.avif";
import invescoqqq from "./assets/landing/invescoqqq.avif";
import roseinc from "./assets/landing/roseinc.avif";
import sG from "./assets/landing/s_g.avif";
import sG2x from "./assets/landing/s_g@2x.avif";
import scentsOfSelf from "./assets/landing/scents_of_self.avif";
import scentsOfSelf2x from "./assets/landing/scents_of_self@2x.avif";
import shapewoman from "./assets/landing/shapewoman.avif";
import shapewoman2x from "./assets/landing/shapewoman@2x.avif";
import soapGlory from "./assets/landing/soap_glory.avif";
import soapGlory2x from "./assets/landing/soap_glory@2x.avif";
import specialK from "./assets/landing/special_k.avif";
import specialK2x from "./assets/landing/special_k@2x.avif";
import thGif from "./assets/landing/LG.gif";
import thLogo from "./assets/landing/th_logo.png";
import tjmaxx from "./assets/landing/tjmaxx.avif";
import tjmaxx2x from "./assets/landing/tjmaxx@2x.avif";
import stanley from "./assets/landing/stanley.avif";
import valentino from "./assets/landing/valentino.avif";
import valentino2x from "./assets/landing/valentino@2x.avif";
import wendys from "./assets/landing/wendys.avif";
import wendys2x from "./assets/landing/wendys@2x.avif";

const LandingBackground = () => {
  const containerRef = React.useRef(null);

  React.useEffect(() => {
    const handleResize = () => {
      const container = containerRef.current;
      if (!container) return;

      const scaleX = container.offsetWidth / 1920;
      const scaleY = container.offsetHeight / 1080;
      // Use Math.max instead of Math.min to ensure no whitespace
      const scale = Math.max(scaleX, scaleY);

      const content = container.firstChild;
      if (content) {
        content.style.transform = `translate(-50%, -50%) scale(${scale})`;
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial scale

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="landing-container" ref={containerRef}>
      <div className="landing">
        <img 
          className="proactiv" 
          alt="Proactiv" 
          src={proactiv}
          srcSet={`${proactiv} 1x, ${proactiv2x} 2x`}
        />

        <img 
          className="ibm" 
          alt="Ibm" 
          src={ibm}
          srcSet={`${ibm} 1x, ${ibm2x} 2x`}
        />

        <img 
          className="ole" 
          alt="Ole" 
          src={ole}
          srcSet={`${ole} 1x, ${ole2x} 2x`}
        />

        <img 
          className="valentino" 
          alt="Valentino" 
          src={valentino}
          srcSet={`${valentino} 1x, ${valentino2x} 2x`}
        />

        <img 
          className="ralph-lauren" 
          alt="Ralph lauren" 
          src={ralphLauren}
          srcSet={`${ralphLauren} 1x`}
        />

        <div className="overlap">
          <div className="overlap-group">
            <img 
              className="birchbox" 
              alt="Birchbox" 
              src={birchbox}
              srcSet={`${birchbox} 1x, ${birchbox2x} 2x`}
            />

            <img 
              className="pringles" 
              alt="Pringles" 
              src={pringles}
              srcSet={`${pringles} 1x, ${pringles2x} 2x`}
            />

            <img 
              className="carrie" 
              alt="Carrie" 
              src={carrie}
              srcSet={`${carrie} 1x`}
            />
          </div>

          <img 
            className="plated" 
            alt="Plated" 
            src={plated}
            srcSet={`${plated} 1x, ${plated2x} 2x`}
          />
        </div>

        <img 
          className="GHD" 
          alt="Ghd" 
          src={GHD}
          srcSet={`${GHD} 1x`}
        />

        <div className="div">
          <img 
            className="jake-and-coke" 
            alt="Jake and coke" 
            src={jakeAndCoke}
            srcSet={`${jakeAndCoke} 1x`}
          />

          <img 
            className="special-k" 
            alt="Special k" 
            src={specialK}
            srcSet={`${specialK} 1x, ${specialK2x} 2x`}
          />
        </div>

        <img 
          className="makers-mark" 
          alt="Makers mark" 
          src={makersMark}
          srcSet={`${makersMark} 1x, ${makersMark2x} 2x`}
        />

        <img 
          className="jetblue-bermuda" 
          alt="Jetblue bermuda" 
          src={jetblueBermuda}
          srcSet={`${jetblueBermuda} 1x`}
        />

        <img 
          className="nike" 
          alt="Nike" 
          src={nike}
          srcSet={`${nike} 1x, ${nike2x} 2x`}
        />

        <img 
          className="elemis" 
          alt="Elemis" 
          src={elemis}
          srcSet={`${elemis} 1x, ${elemis2x} 2x`}
        />

        <div className="overlap-2">
          <div className="overlap-3">
            <div className="overlap-4">
              <img 
                className="big-square" 
                alt="Big square" 
                src={bigSquare}
                srcSet={`${bigSquare} 1x, ${bigSquare2x} 2x`}
              />

              <img 
                className="lush" 
                alt="Lush" 
                src={lush}
                srcSet={`${lush} 1x, ${lush2x} 2x`}
              />
            </div>

            <img 
              className="lindt" 
              alt="Lindt" 
              src={lindt}
              srcSet={`${lindt} 1x, ${lindt2x} 2x`}
            />
          </div>

          <img 
            className="wendys" 
            alt="Wendys" 
            src={wendys}
            srcSet={`${wendys} 1x, ${wendys2x} 2x`}
          />

          <img 
            className="harrys" 
            alt="Harrys" 
            src={harrys}
            srcSet={`${harrys} 1x, ${harrys2x} 2x`}
          />
        </div>

        <img 
          className="converse" 
          alt="Converse" 
          src={converse}
          srcSet={`${converse} 1x, ${converse2x} 2x`}
        />

        <img 
          className="tjmaxx" 
          alt="Tjmaxx" 
          src={tjmaxx}
          srcSet={`${tjmaxx} 1x, ${tjmaxx2x} 2x`}
        />

        <img 
          className="cetaphil" 
          alt="Cetaphil" 
          src={cetaphil}
          srcSet={`${cetaphil} 1x`}
        />

        <img 
          className="narcon-house" 
          alt="Narcon house" 
          src={narconHouse}
          srcSet={`${narconHouse} 1x, ${narconHouse2x} 2x`}
        />

        <img 
          className="olly-girlsroom" 
          alt="Olly girlsroom" 
          src={ollyGirlsroom}
          srcSet={`${ollyGirlsroom} 1x, ${ollyGirlsroom2x} 2x`}
        />

        <img 
          className="ikea" 
          alt="Ikea" 
          src={ikea}
          srcSet={`${ikea} 1x, ${ikea2x} 2x`}
        />

        <div className="overlap-group-2">
          <img 
            className="knorr" 
            alt="Knorr" 
            src={knorr}
            srcSet={`${knorr} 1x, ${knorr2x} 2x`}
          />

          <img 
            className="e-pc" 
            alt="E pc" 
            src={ePc}
            srcSet={`${ePc} 1x, ${ePc2x} 2x`}
          />
        </div>

        <img 
          className="big-long-rec" 
          alt="Big long rec" 
          src={farmacy}
          srcSet={`${farmacy} 1x`}
        />

        <img 
          className="benefit" 
          alt="Benefit" 
          src={benefit}
          srcSet={`${benefit} 1x, ${benefit2x} 2x`}
        />

        <img 
          className="ralph-lauren-avicii" 
          alt="Ralph lauren avicii" 
          src={ralphLaurenAvicii}
          srcSet={`${ralphLaurenAvicii} 1x, ${ralphLaurenAvicii2x} 2x`}
        />
        
        <img 
          className="th-lg-3" 
          alt="Th lg" 
          src={thGif}
          srcSet={`${thGif} 1x`}
        />

        <img 
          className="th-logo" 
          alt="Th logo" 
          src={thLogo}
          srcSet={`${thLogo} 1x`}
        />

        <img 
          className="dyson" 
          alt="Dyson" 
          src={dyson}
          srcSet={`${dyson} 1x, ${dyson2x} 2x`}
        />

        <img 
          className="scents-of-self" 
          alt="Scents of self" 
          src={scentsOfSelf}
          srcSet={`${scentsOfSelf} 1x, ${scentsOfSelf2x} 2x`}
        />

        <img 
          className="peloton" 
          alt="Peloton" 
          src={peloton}
          srcSet={`${peloton} 1x, ${peloton2x} 2x`}
        />

        <img 
          className="rec" 
          alt="Rec" 
          src={invescoqqq}
          srcSet={`${invescoqqq} 1x`}
        />

        <img 
          className="soap-glory" 
          alt="Soap glory" 
          src={soapGlory}
          srcSet={`${soapGlory} 1x, ${soapGlory2x} 2x`}
        />

        <img 
          className="kidney-island" 
          alt="Kidney island" 
          src={kidneyIsland}
          srcSet={`${kidneyIsland} 1x`}
        />

        <img 
          className="union" 
          alt="Stanley" 
          src={stanley}
          srcSet={`${stanley} 1x`}
        />

        <img 
          className="s-g" 
          alt="S g" 
          src={sG}
          srcSet={`${sG} 1x, ${sG2x} 2x`}
        />

        <img 
          className="roseinc" 
          alt="Roseinc" 
          src={roseinc}
          srcSet={`${roseinc} 1x`}
        />

        <img 
          className="shapewoman" 
          alt="Shapewoman" 
          src={shapewoman}
          srcSet={`${shapewoman} 1x, ${shapewoman2x} 2x`}
        />
      </div>
    </div>
  );
};

export default LandingBackground;
