const activations = [
    {
        thumbnailUrl: "/activations/apollo-50th-anniversary/thumb.png",
        subsection:"Live Events",
        path: "/activations/apollo-50th-anniversary/",
        client: "IBM",
        projectName: "Apollo 50th Anniversary",
        type: "activations",
        legacyProject: true,
        description:
          "Celebrating the 50th anniversary Apollo 11 moon landing, TH brought to life an IBM brand moment that surprised thousands of people passing through the Oculus with a digitally interactive simulated moonwalk experience. Powered by a combination of AI, responsive digital screens and flooring, guests could walk on the moon and create a short video of themselves as astronauts on the moon to share creating over 900,000 impressions. This installation highlighted IBM’s role both in the original mission and down to today with dynamic graphic displays and immersive vignettes. TH designed and installed living room sets throughout the decades, replicating experiences where families would gather to hear the news on space innovation. The authentic decor elements offered interactive moments like playable Atari for the 70’s vignette or floating art pieces for a future-inspired vignette. More than mere photo moments, the sets generated memories and emotional connections with all who participated.",
        gallery: [
          {
            url: "/activations/apollo-50th-anniversary/apollo-50th_1.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/apollo-50th-anniversary/apollo-50th_2.jpg",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/apollo-50th-anniversary/apollo-50th_3.jpg",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/apollo-50th-anniversary/apollo-50th_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/apollo-50th-anniversary/apollo-50th_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/apollo-50th-anniversary/apollo-50th_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/apollo-50th-anniversary/apollo-50th_7.jpg",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/apollo-50th-anniversary/apollo-50th_8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/apollo-50th-anniversary/apollo-50th_9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/apollo-50th-anniversary/apollo-50th_10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/apollo-50th-anniversary/apollo-50th_11.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/apollo-50th-anniversary/apollo-50th_12.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/sephora-slide-into-sephora/thumb.png",
        subsection:"Live Events",
        path: "/activations/sephora-slide-into-sephora/",
        client: "Sephora",
        projectName: "Slide Into Sephora",
        type: "activations",
        legacyProject: false,
        description:
          `To celebrate their new store opening in NYC’s Times square, TH created a one-day, oversized interactive stunt with two 25 foot custom slides. Guests of all ages enjoyed letting go of their inner child gliding down into a massive ball pit. Boomerangs were automatically generated for each person driving organic social sharing. For even more content creation, TH created four larger-than-life props that celebrated New York City and the SEPHORA brand. 

        <p>The sheer size of the activation and its Times Square location drew in tons of passersby and gave them plenty of opportunities to play, take photos and enjoy themselves. With the new Sephora store in such close proximity, this awareness and interest drove traffic directly to the store.</p>`,
        gallery: [
                {
                  url: "/activations/sephora-slide-into-sephora/hero.png",
                  alt: "",
                  sizeClass: "full",
                },
                {
                    url: "/activations/sephora-slide-into-sephora/1.png",
                  alt: "",
                  sizeClass: "half",
                },
                {
                    url: "/activations/sephora-slide-into-sephora/2.png",
                  alt: "",
                  sizeClass: "half",
                },
                {
                    url: "/activations/sephora-slide-into-sephora/3.png",
                  alt: "",
                  sizeClass: "onethird",
                },
                {
                    url: "/activations/sephora-slide-into-sephora/4.png",
                  alt: "",
                  sizeClass: "onethird",
                },
                {
                    url: "/activations/sephora-slide-into-sephora/5.png",
                  alt: "",
                  sizeClass: "onethird",
                },
                {
                    url: "/activations/sephora-slide-into-sephora/6.png",
                  alt: "",
                  sizeClass: "twothird",
                },
                {
                    url: "/activations/sephora-slide-into-sephora/7.png",
                  alt: "",
                  sizeClass: "onethird",
                },
                {
                    url: "/activations/sephora-slide-into-sephora/8.png",
                  alt: "",
                  sizeClass: "half",
                },
                {
                    url: "/activations/sephora-slide-into-sephora/9.png",
                  alt: "",
                  sizeClass: "half",
                }
        ],
      },
      {
        thumbnailUrl: "/activations/uno-championship/thumb.png",
        subsection:"Live Events",
        path: "/activations/uno-championship/",
        client: "Uno",
        projectName: "Championship",
        type: "activations",
        legacyProject: true,
        description:
          "We held the first ever UNO card game tournament for Mattel with a Covid-safe 150 person live event in Las Vegas to celebrate the brand's 50th anniversary. We strategized a hybrid approach to deliver the most accessible and inclusive experience for their global audience. The tournament hosted by eSports gaming celebrity Ninja, and was streamed live on the brand’s newly launched TikTok channel and viewed by over 460,000 fans globally.",
        gallery: [
          {
            url: "https://player.vimeo.com/video/655533034?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
            type: "vimeo",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/uno-championship/uno-2.png",
            alt: "",
            subtitle: "In-Person",
            sizeClass: "half",
          },
          {
            url: "/activations/uno-championship/uno-3.gif",
            alt: "",
            subtitle: "Virtual Extension on Tiktok",
            sizeClass: "half",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/joah-glow-with-glass-skin-popup/thumb.png",
        subsection:"Live Events",
        path: "/activations/joah-glow-with-glass-skin-popup/",
        client: "Joah",
        projectName: "Glow With Glass Skin Popup",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH Experiential partnered with the D2C brand Joah Beauty brand to create an engaging and educational experience to launch their rebrand and drive more consumer awareness. </p>
          <p>We designed and produced an eye-catching, interactive pop-up activation, located in two high-traffic locations in NYC and LA, that introduced consumers to K-Beauty, Joah’s storytelling and positioning, and showcasing the rebranded Core Collection.</p>
          <p>The activation offered guests a unique consumer journey, guiding them through the marketing funnel, via an interactive polling wall where we captured 100% of consumer data, oversized domes with product trials, 360 photo booth activity, and 88% of attendees creating and sharing content throughout the space.</p>`,
        gallery: [
            {
                url: "https://player.vimeo.com/video/1047629633?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
                type: "vimeo",
                alt: "",
                sizeClass: "full",
              },
          {
            url: "/activations/joah-glow-with-glass-skin-popup/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/joah-glow-with-glass-skin-popup/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/joah-glow-with-glass-skin-popup/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/joah-glow-with-glass-skin-popup/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/joah-glow-with-glass-skin-popup/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/joah-glow-with-glass-skin-popup/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/joah-glow-with-glass-skin-popup/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/joah-glow-with-glass-skin-popup/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/joah-glow-with-glass-skin-popup/9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/joah-glow-with-glass-skin-popup/10.gif",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/joah-glow-with-glass-skin-popup/11.png",
            alt: "",
            sizeClass: "twothird",
          }
        ],
      },
      {
        thumbnailUrl: "/activations/kiss-summer-beach-club/thumb.png",
        subsection:"Live Events",
        path: "/activations/kiss-summer-beach-club/",
        client: "Kiss",
        projectName: "Summer Beach Club",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH partnered with KISS Products to create a unique pop-up experience for consumers to showcase the innovative, no glue needed imPRESS Beauty Mani, Pedi, & Lash products that offered press and influencers an exclusive brand team brunch. </p>

<p>In under 12 hours we had 2,500 guests, captured their data for the brand and executed 1,500 application services. They were gifted the tools to continue using the products in the future - bringing this D2C brand directly in front of new audiences and converting them into fans of the brand.</p>`,
          gallery: [
            {
                url: "https://player.vimeo.com/video/878430866?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
                type: "vimeo",
                alt: "",
                sizeClass: "full"
          },
          {
            url: "/activations/kiss-summer-beach-club/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/kiss-summer-beach-club/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/kiss-summer-beach-club/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/kiss-summer-beach-club/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/kiss-summer-beach-club/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/kiss-summer-beach-club/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/kiss-summer-beach-club/7.png",
            alt: "",
            sizeClass: "onethird",
          },    
          {
            url: "/activations/kiss-summer-beach-club/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/kiss-summer-beach-club/9.png",
            alt: "",
            sizeClass: "half",
          }
          
        ],
      },
      {
        thumbnailUrl: "/activations/tjmaxx-the-game-of-maxximizing/thumb.png",
        subsection:"Live Events",
        path: "/activations/tjmaxx-the-game-of-maxximizing/",
        client: "T.J. Maxx",
        projectName: "The Game Of #Maxximizing",
        type: "activations",
        legacyProject: true,
        description:
          "T.J. Maxx wanted to introduce a new term to retail - Maxximizing, to let consumers know they don’t have to sacrifice quality or price when it comes to finding their favorite items. To announce this high value approach to shopping, TH helped launch a multi-day, multi-city interactive game that gave customers a hands-on experience with getting more for their money than they may have thought possible, and in a fun way. Each game was housed in a photo-worthy pod and featured items curated by celebrity influencers and designers like Hannah Simone and Alli Webb centered around unique themes like “Maxximize Your Vacay” or “Maxximize Like a Boss.” To play onsite, participants could snap a picture and post with their guess of the dollar value to Instagram using the designated hashtags for a chance to win everything inside. Extending the contest beyond the footprint, consumers could also view the pod on the brand’s Instagram account as well as on partner influencers social accounts and offer their guesses in the comments section. T.J. Maxx announced a winner with the closest guess at the end of each day.",
        gallery: [
          {
            url: "/activations/tjmaxx-the-game-of-maxximizing/game-of-maxximizing_1.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/tjmaxx-the-game-of-maxximizing/game-of-maxximizing_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/tjmaxx-the-game-of-maxximizing/game-of-maxximizing_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/tjmaxx-the-game-of-maxximizing/game-of-maxximizing_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/tjmaxx-the-game-of-maxximizing/game-of-maxximizing_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/tjmaxx-the-game-of-maxximizing/game-of-maxximizing_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/tjmaxx-the-game-of-maxximizing/game-of-maxximizing_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/tjmaxx-the-game-of-maxximizing/game-of-maxximizing_8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/tjmaxx-the-game-of-maxximizing/game-of-maxximizing_9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/tjmaxx-the-game-of-maxximizing/game-of-maxximizing_10.png",
            alt: "",
            sizeClass: "half",
          }
        ],
      },
      {
        thumbnailUrl: "/activations/jetblue-bermuda/thumb.png",
        subsection:"Live Events",
        path: "/activations/jetblue-bermuda/",
        client: "Jetblue",
        projectName: "Bermuda",
        type: "activations",
        legacyProject: false,
        description:
        `<p>TH partnered with JetBlue to create a pop-up in a high traffic area of NYC launching their newest 90 minute flights to Bermuda.</p>
          <p>This pop-up included an 8ft timer glass that rotated every 90 minutes and live demonstrating the incredibly short flight time. We leveraged a combination of several digital/tech components to bring this activation to life including a microsite to facilitate a sweepstakes sign up where guests could win a ticket, embedded mobile trivia experiences to learn more about the destination, and an Integrated immersive mixed reality experience transporting people to the pink sands of Bermuda.</p>`,
        gallery: [
            {
                url: "https://player.vimeo.com/video/967057560?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
                type: "vimeo",
                alt: "",
                sizeClass: "full"
          },
          {
            url: "/activations/jetblue-bermuda/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/jetblue-bermuda/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/jetblue-bermuda/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/jetblue-bermuda/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/jetblue-bermuda/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/jetblue-bermuda/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/jetblue-bermuda/7.gif",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/jetblue-bermuda/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/jetblue-bermuda/9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/jetblue-bermuda/10.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/jetblue-bermuda/11.png",
            alt: "",
            sizeClass: "twothird",
          }
        ],
      },
      {
        thumbnailUrl: "/activations/lush-in-the-park/thumb.png",
        subsection:"Live Events",
        path: "/activations/lush-in-the-park/",
        client: "Lush",
        projectName: "In the Park",
        type: "activations",
        legacyProject: true,
        description:
          "TH teamed up with Lush Fresh Handmade Cosmetics to design and produce a unique moment to engage their community and new audiences around what they care about: insanely fresh ingredients and products. The brand’s behind-the-scenes chemists, a.k.a. Compounders, were brought to the forefront, demonstrating live how they make Lush products from citrus fruits, plants, grains and more. Oversized living walls covered in over twelve different plants including Asplenium, Japanese Birdsnest, Tri-color Oyster, Ferns and Ivy lead guests into the #LushInThePark interactive experience. Mounted touch screen monitors allowed people to select fragrance preferences and paired them with a Lush Compounder. At the Compounder Bar, guests’ product was handmade in front of them, a live storytelling moment on how truly “fresh” Lush’s products are.",
        statBar: [
          {
            statNumber: "3",
            statName: "activation days",
          },
          {
            statNumber: "150",
            statName: "attendees per hour",
          },
          {
            statNumber: "123,000+",
            statName: "Facebook impressions",
          },
          {
            statNumber: "2,916,245",
            statName: "live stream views",
          },
          {
            statNumber: "45,000",
            statName: "visual impressions",
          },
          {
            statNumber: "10,000",
            statName: "YouTube hits",
          },
        ],
        gallery: [
          {
            url: "/activations/lush-in-the-park/lush-in-the-park_1.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/lush-in-the-park/lush-in-the-park_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/lush-in-the-park/lush-in-the-park_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/lush-in-the-park/lush-in-the-park_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/lush-in-the-park/lush-in-the-park_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/lush-in-the-park/lush-in-the-park_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/lush-in-the-park/lush-in-the-park_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/lush-in-the-park/lush-in-the-park_8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/lush-in-the-park/lush-in-the-park_9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/lush-in-the-park/lush-in-the-park_10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/lush-in-the-park/lush-in-the-park_11.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/lush-in-the-park/lush-in-the-park_12.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/special-k-ownit/thumb.png",
        subsection:"Live Events",
        path: "/activations/special-k-ownit/",
        client: "Special K",
        projectName: "#OWNIT",
        type: "activations",
        legacyProject: true,
        description:
          "New research commissioned by Special K shows 90 percent of women in the United States question or doubt their food and spend 61 minutes second-guessing their choices—every day. In celebration of Special K’s #OWNIT campaign, TH partnered with Krispr and Special K to produce an activation that encourages woman to combat any self-doubt about their food choices and eat with confidence. Taking over Time Square Plaza, TH built an oversized “Digital Clock” that rotated custom content for one hour and one minute displaying examples of how real women second-guess their food choices. A surprise appearance by Taraji P. Henson drove attendees to #OWNIT and ditch their food doubts with Special K. While on the plaza, guests also had the opportunity to sample Special K and have their photo taken and shared on Special K’s custom Time Square billboard for the day.",
        gallery: [
          {
            url: "/activations/special-k-ownit/ownit_1.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/special-k-ownit/ownit_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/special-k-ownit/ownit_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/special-k-ownit/ownit_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/special-k-ownit/ownit_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/special-k-ownit/ownit_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/special-k-ownit/ownit_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/special-k-ownit/ownit_8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/special-k-ownit/ownit_9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/special-k-ownit/ownit_10.png",
            alt: "",
            sizeClass: "half",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/e-network-race-to-pca/thumb.png",
        subsection:"Live Events",
        path: "/activations/e-network-race-to-pca/",
        client: "E!PCA",
        projectName: "Race to PCAs",
        subLabel: "E! People's Choice Awards",
        subLabel: "E! People's Choice Awards",
        type: "activations",
        legacyProject: true,
        description:
          "NBC came to TH looking to ignite passions for pop culture across the U.S. for their inaugural broadcast of the People’s Choice Awards on E!. As the only award show that gives the power to the people, TH sought to celebrate each fan’s vote in a way that made it feel special and shareable. Inviting consumers to participate with fun messaging like “Voting Never Tasted So Good,” passersby were drawn in by a modern branded Airstream where they learn about the awards, voted for their favorite pop-culture icon and receive an Instagrammable edible gift. Each voter received a custom decorated donut packaged in E! PCA cartons. Cartons and voter pins were personalized with their choice of nominee. Consumers could share their voting experience on social and build even more support for their favorite celebrity or show. The physical engagement transformed voting into a memorable experience and incentivized consumers to tune in to the People’s Choice Awards live. In the end, TH took the experience to three markets and inspired more than 15,000 people to cote and generated over 2,000,000 million visual impressions for the award show.",
        gallery: [
          {
            url: "/activations/e-network-race-to-pca/race-to-pcas_1.jpg",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/e-network-race-to-pca/race-to-pcas_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/e-network-race-to-pca/race-to-pcas_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/e-network-race-to-pca/race-to-pcas_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/e-network-race-to-pca/race-to-pcas_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/e-network-race-to-pca/race-to-pcas_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/e-network-race-to-pca/race-to-pcas_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/e-network-race-to-pca/race-to-pcas_8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/e-network-race-to-pca/race-to-pcas_9.jpg",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/e-network-race-to-pca/race-to-pcas_10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/e-network-race-to-pca/race-to-pcas_11.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/e-network-race-to-pca/race-to-pcas_12.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/ikea-pop-up-studio-apartment/thumb.png",
        subsection:"Live Events",
        path: "/activations/ikea-pop-up-studio-apartment/",
        client: "Ikea",
        projectName: "Pop Up Apartment",
        type: "activations",
        legacyProject: true,
        description:
          "For the grand opening of the Brooklyn IKEA store, TH partnered with Deutsch and IKEA to fabricate, produce and install a quintessential New York City studio apartment, fully outfitted in over 150 IKEA furniture pieces and accents. The 400-square-foot pop-up travelled throughout Manhattan and Brooklyn - Union Square, Borough Hall, the Brooklyn Public Library and Cadman Plaza - garnering buzz and awareness over the course of four consecutive days leading up to the store’s opening. TH's production team worked closely with IKEA's designers to ensure all program elements met the brand’s display standards while seamlessly installing the structure overnight at each location. As a result, the store opening saw over $1 million in sales with attendance exceeding 30,000.",
        statBar: [
          {
            statNumber: "4",
            statName: "activation days",
          },
          {
            statNumber: "569,969",
            statName: "visual impressions",
          },
          {
            statNumber: "30,000",
            statName: "shoppers during opening weekend",
          },
          {
            statNumber: "$1,000,000",
            statName: "in sales during opening weekend",
          },
        ],
        gallery: [
          {
            url: "/activations/ikea-pop-up-studio-apartment/ikea_popup-featured-lg.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/ikea-pop-up-studio-apartment/ikea-pop-up_1.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/ikea-pop-up-studio-apartment/ikea-pop-up_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/ikea-pop-up-studio-apartment/ikea-pop-up_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/ikea-pop-up-studio-apartment/ikea-pop-up_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ikea-pop-up-studio-apartment/ikea-pop-up_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ikea-pop-up-studio-apartment/ikea-pop-up_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ikea-pop-up-studio-apartment/ikea-pop-up_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/ikea-pop-up-studio-apartment/ikea-pop-up_8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ikea-pop-up-studio-apartment/ikea-pop-up_9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/ikea-pop-up-studio-apartment/ikea-pop-up_10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/ikea-pop-up-studio-apartment/ikea-pop-up_11.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ikea-pop-up-studio-apartment/ikea-pop-up_12.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/converse-made-by-you/thumb.png",
        subsection:"Live Events",
        path: "/activations/converse-made-by-you/",
        client: "Converse",
        projectName: "Made By You",
        type: "activations",
        legacyProject: true,
        description:
          "Converse came to TH to help animate IRL the “Made by You” campaign celebrating the classic Chuck Taylor as a shoe truly defined by those that wear it. To celebrate the unique wear, tear, and custom additions that Chuck Taylor Converse wearers have been adding for years, TH created an interactive outdoor museum-style installation that highlighted customized shoes from noted designers, athletes, and artists - from Patti Smith to local musicians. The series of thirteen eye-catching lightboxes, placed in Flat Iron Square, featured real shoes from real people that transformed Chucks into their artistic canvas. To celebrate the beauty of a life-time of wear, tear, and love, TH pioneered early-stage Google Cardboard integration to enable consumers to experience the shoes of international icons in virtual reality. This combination of visual storytelling and interactive technology helped emotionally connect guests with Converse and stories of people authentically expressing themselves.",
        statBar: [
          {
            statNumber: "6",
            statName: "activation days",
          },
          {
            statNumber: "8,705",
            statName: "unique engagements",
          },
          {
            statNumber: "1,598,568",
            statName: "visual impressions",
          },
        ],
        gallery: [
          {
            url: "/activations/converse-made-by-you/made-by-you_1.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/converse-made-by-you/made-by-you_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/converse-made-by-you/made-by-you_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/converse-made-by-you/made-by-you_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/converse-made-by-you/made-by-you_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/converse-made-by-you/made-by-you_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/converse-made-by-you/made-by-you_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/converse-made-by-you/made-by-you_8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/converse-made-by-you/made-by-you_9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/converse-made-by-you/made-by-you_10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/converse-made-by-you/made-by-you_11.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/converse-made-by-you/made-by-you_12.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/mirror-retail-pop-up-shop/thumb.png",
        subsection:"Pop Ups",
        path: "/activations/mirror-retail-pop-up-shop/",
        client: "MIRROR",
        projectName: "Retail Pop Up Shop",
        type: "activations",
        legacyProject: false,
        description:
        `<p>TH designed, developed and executed a retail experiential pop-up: a temporary brick and mortar store/boutique fitness studio that gave guests a firsthand introduction to Mirror’s technology, to experience how it could take their home fitness game to a whole new level. </p>
          <p>The overall store interior was designed to have a contemporary setting to effectively showcase the product with a clean and minimal aesthetic. Each space was used for demonstrating the product, offering an opportunity for guests to interact with the technology and trial a workout. The storefront window showcased two Mirrors coupled with displays featuring a slow motion workout animation catching the attention of shoppers in the highly foot trafficked Flatiron neighborhood, while our team of retail specialists were onsite seven days per week to handle all product demo and sales.</p>`,
        gallery: [
          {
            url: "/activations/mirror-retail-pop-up-shop/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/mirror-retail-pop-up-shop/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/mirror-retail-pop-up-shop/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/mirror-retail-pop-up-shop/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/mirror-retail-pop-up-shop/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/mirror-retail-pop-up-shop/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/mirror-retail-pop-up-shop/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/mirror-retail-pop-up-shop/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          
          
        ],
      },
      {
        thumbnailUrl: "/activations/pringles-stack-shack/thumb.png",
        subsection:"Pop Ups",
        path: "/activations/pringles-stack-shack/",
        client: "Pringles",
        projectName: "Stack Shack",
        type: "activations",
        legacyProject: true,
        description:
          "To create a moment where the Pringles brand and fans could unite to celebrate the iconic chip, TH designed and produced the “Stackateria,” a three-day pop-up inviting people to come in and “Get Stacking” and immerse themselves into a Pringle-filled world. The “Stackateria” popped up in Times Square with an oversized Pringles can as the entryway offering a surprise and delight moment and drawing in foot traffic. Once inside, guests could see, mix, taste, and try everything Pringles throughout the space and create their own Pringles stories: Tasting Station - Larger-than-life Pringles cans adorned the space where guests could sample their favorite, or entirely new, flavors. Create Your Own Stack - Guests were able to craft their very own custom concoction of flavors into a signature stack to take home. Blind Stack Challenge - Competing for a chance at a free Pringles shirt, consumers donned a branded blindfold and tested their flavor recognition skills. Social Photo Booth - A stylized version of the iconic New York City skyline offered a natural photo backdrop as guests posed with their stacks and created gifs for social sharing. Over 2,268 guests “Got Stacking” and created 2,504 custom Pringle stacks and over 900 brand stories shared on social.",
        gallery: [
          {
            url: "/activations/pringles-stack-shack/stack-shack_1.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/pringles-stack-shack/stack-shack_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/pringles-stack-shack/stack-shack_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/pringles-stack-shack/stack-shack_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/pringles-stack-shack/stack-shack_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/pringles-stack-shack/stack-shack_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/pringles-stack-shack/stack-shack_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/pringles-stack-shack/stack-shack_8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/pringles-stack-shack/stack-shack_9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/pringles-stack-shack/stack-shack_10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/pringles-stack-shack/stack-shack_11.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/pringles-stack-shack/stack-shack_12.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/botanist-be-the-botanist/thumb.png",
        subsection:"Pop Ups",
        path: "/activations/botanist-be-the-botanist/",
        client: "Botanist",
        projectName: "#BETHEBOTATNIST",
        type: "activations",
        legacyProject: false,
        description:
          `<p>The Botanist Gin enlisted the support of TH to bring the brand to life through a 16 day, 4 city pop-up tour, culminating at Art Basel in Miami. Our mission was to celebrate the brand's philosophy of "locally foraged flavors" and share the story behind its 22 hand-foraged botanicals.</p>
<p>Across San Francisco, Dallas, New York and Miami, we hosted immersive experiences that included workshops, craft cocktail classes, and exclusive media dinners. Each city featured menus inspired by The Botanist Gin, curated by local influencer chefs, alongside interactive installations, bringing the gin’s heritage to life.</p>`,
        gallery: [
          {
            url: "/activations/botanist-be-the-botanist/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/botanist-be-the-botanist/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/botanist-be-the-botanist/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/botanist-be-the-botanist/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/botanist-be-the-botanist/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/botanist-be-the-botanist/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/botanist-be-the-botanist/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/botanist-be-the-botanist/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/botanist-be-the-botanist/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/botanist-be-the-botanist/9.png",
            alt: "",
            sizeClass: "half",
          }
        ],
      },
      {
        thumbnailUrl: "/activations/rose-inc-lipstick-lounge/thumb.png",
        subsection:"Pop Ups",
        path: "/activations/rose-inc-lipstick-lounge/",
        client: "Rose Inc",
        projectName: "Lipstick Lounge",
        type: "activations",
        legacyProject: true,
        description:
          "We recently partnered with Rosie Huntington Whitley’s newly launched beauty line, Rose Inc, to create a novel pop-up experience in NYC. Guests were able to check in with their mobile phone, select what experiences and services they were interested in and book them. This strategy engaged 87% of attendees and 70% participated in one-on-one makeup services. <br /><br />We leveraged interactive technology experiences to immerse guests in the brand campaign and messaging. The pop-up entrance featured a reactive LED floor that revealed all of the newly launching lipstick shade names as people walked over. It provided an organic photo moment. They could also access augmented reality filters that surprised them with a lipstick try on moment and fun campaign branded overlays. People who posted their content to the hashtag were featured on a live UGC digital display wall onsite.",
        gallery: [
          {
            url: "https://player.vimeo.com/video/1021911429?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
            type: "vimeo",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/rose-inc-lipstick-lounge/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/rose-inc-lipstick-lounge/3.jpg",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/rose-inc-lipstick-lounge/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/rose-inc-lipstick-lounge/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/rose-inc-lipstick-lounge/6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/rose-inc-lipstick-lounge/7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/rose-inc-lipstick-lounge/8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/rose-inc-lipstick-lounge/9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/rose-inc-lipstick-lounge/10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/rose-inc-lipstick-lounge/11.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/rose-inc-lipstick-lounge/12.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/blue-apron-unboxed-pop-up/thumb.png",
        subsection:"Pop Ups",
        path: "/activations/blue-apron-unboxed-pop-up/",
        client: "Blue Apron",
        projectName: "Unboxed Pop Up",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH created a month-long pop-up in the Flatiron District of New York for Blue Apron that celebrated home cooking and featured multiple culinary experiences. Programming included cooking classes, arts & crafts, cookbook author panels, and free samples from local farm to table partners. </p>
<p>The pop-up then packed up and hit the road through a multisensory 3-stop mobile tour featuring a four-part Instagrammable experience. Consumers learned about the source of Blue Apron food through an interactive wall coloring mural, immersed themselves in a 3D colorful ingredients display, smelled and tried ingredients in the interactive test kitchen, and saw brand love stories from the existing Blue Apron community.</p>`,
        gallery: [
          {
            url: "/activations/blue-apron-unboxed-pop-up/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/blue-apron-unboxed-pop-up/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/blue-apron-unboxed-pop-up/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/blue-apron-unboxed-pop-up/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/blue-apron-unboxed-pop-up/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/blue-apron-unboxed-pop-up/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/blue-apron-unboxed-pop-up/6.png",
            alt: "",
            sizeClass: "twothird",  
          },
          {
            url: "/activations/blue-apron-unboxed-pop-up/7.png",
            alt: "",
            sizeClass: "onethird",  
          },
          {
            url: "/activations/blue-apron-unboxed-pop-up/8.png",
            alt: "",
            sizeClass: "half",  
          },
          {
            url: "/activations/blue-apron-unboxed-pop-up/9.png",
            alt: "",
            sizeClass: "half",  
          }
        ],
      },
      {
        thumbnailUrl: "/activations/pnc-gingerbread-branch/thumb.png",
        subsection:"Pop Ups",
        path: "/activations/pnc-gingerbread-branch/",
        client: "PNC",
        projectName: "Gingerbread Branch",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH partnered to produce the world’s first-ever Gingerbread PNC Branch at Penn’s Landing in Philadelphia. Located within Winterfest, the branch was constructed with over 5,000 pounds of actual gingerbread and embellished with traditional gingerbread house fixings—from faux chocolate windows to candied finishes—all in promotion of PNC’s annual Christmas Price Index (CPI).&nbsp;</p>
            <p>The inside of the branch featured intricate columns, showcasing the 12 gifts of the CPI, a Teller Window distributing complimentary hot cocoa and gingerbread dollar sign cookies, a fully functional PNC ATM for onsite banking needs, a cookie bank vault, and a photo op via a chocolate candy dollar cut-out. Guests were encouraged to take photos and post to social media using the hashtag #GingerbreadBranch.</p>`,
        gallery: [
          {
            url: "/activations/pnc-gingerbread-branch/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/pnc-gingerbread-branch/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/pnc-gingerbread-branch/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/pnc-gingerbread-branch/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/pnc-gingerbread-branch/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/pnc-gingerbread-branch/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/pnc-gingerbread-branch/6.png",
            alt: "",
            sizeClass: "twothird",  
          },
          {
            url: "/activations/pnc-gingerbread-branch/7.png",
            alt: "",
            sizeClass: "onethird",  
          },
          {
            url: "/activations/pnc-gingerbread-branch/8.png",
            alt: "",
            sizeClass: "half",  
          },
          {
            url: "/activations/pnc-gingerbread-branch/9.png",
            alt: "",
            sizeClass: "half",  
          }
        ],
      },
      {
        thumbnailUrl: "/activations/alastin-antioxidant-serum-press-launch/thumb.png",
        subsection:"Pop Ups",
        path: "/activations/alastin-antioxidant-serum-press-launch/",
        client: "Alastin",
        projectName: "Antioxidant Serum Press Launch",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH Experiential partnered with ALASTIN Skincare to launch the latest product innovation, C-RADICAL Defense Antioxidant Serum, to press and media attendees.</p>

          <p>We designed and produced a Antioxidant Farmer’s Market Pop-Up to highlight key ingredient storytelling and&nbsp; that featured multiple interactive touchpoints, educational activities and driving organic content creation.</p>`,
        gallery: [
          {
            url: "/activations/alastin-antioxidant-serum-press-launch/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/alastin-antioxidant-serum-press-launch/1.gif",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/alastin-antioxidant-serum-press-launch/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/alastin-antioxidant-serum-press-launch/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/alastin-antioxidant-serum-press-launch/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/alastin-antioxidant-serum-press-launch/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/alastin-antioxidant-serum-press-launch/6.png",
            alt: "",
            sizeClass: "twothird",  
          },
          {
            url: "/activations/alastin-antioxidant-serum-press-launch/7.png",
            alt: "",
            sizeClass: "onethird",  
          },
          {
            url: "/activations/alastin-antioxidant-serum-press-launch/8.png",
            alt: "",
            sizeClass: "half",  
          },
          {
            url: "/activations/alastin-antioxidant-serum-press-launch/9.png",
            alt: "",
            sizeClass: "half",  
          }
        ],
      },
      {
        thumbnailUrl: "/activations/farmacy-farmacyland-popup/thumb.png",
        subsection:"Pop Ups",
        path: "/activations/farmacy-farmacyland-popup/",
        client: "Farmacy",
        projectName: "Farmacyland Popup",
        type: "activations",
        legacyProject: true,
        description:
          "For the D2C brand, Farmacy Beauty, we designed and built their first ever pop-up—FARMACYLAND since their launch seven years ago. We designed an environment and experience that brought to life the brand’s commitment to clean beauty, sustainability, and philanthropy: fabricating with 83% upcycled materials and donating five meals to Feeding America for every guest in attendance.",
        gallery: [
          {
            url: "https://player.vimeo.com/video/822214371?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
            type: "vimeo",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/farmacy-farmacyland-popup/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/farmacy-farmacyland-popup/3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/farmacy-farmacyland-popup/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/farmacy-farmacyland-popup/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/farmacy-farmacyland-popup/6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/farmacy-farmacyland-popup/7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/farmacy-farmacyland-popup/8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/farmacy-farmacyland-popup/9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/farmacy-farmacyland-popup/10.png",
            alt: "",
            sizeClass: "half",
          }
        ],
      },
      {
        thumbnailUrl: "/activations/proactiv-pop-this-not-that/thumb.png",
        subsection:"Pop Ups",
        path: "/activations/proactiv-pop-this-not-that/",
        client: "Proactiv",
        projectName: "Pop This, Not That!",
        type: "activations",
        legacyProject: true,
        description:
          "To get Gen Z excited about skincare and removing the stigma from acne, TH partnered with Proactiv to design an immersive, interactive, and Instagrammable branded pop-up. “Pop This, Not That” was built around the idea that teens shouldn’t have to pop pimples. Instead, we offered a series of highly engaging and shareable experiences that gave the young audience a fun way to pop all things poppable in a playful space. Poppable bubble walls, balloons, pop art walls, poppable bubble gum, popcorn, and soda pop all came together to provide rich storytelling moments throughout the space. These content creation opportunities enabled both influencers and celebrity partners like Cameron Dallas, Olivia Munn and Maite Perroni to generate buzz and inspired teens to share their “Pop This, Not That” photos, making this event a real content hub for the brand and generated 137,673,088 impressions.",
        statBar: [
          {
            statNumber: "1",
            statName: "activation day",
          },
          {
            statNumber: "464,474,904",
            statName: "media impressions",
          },
          {
            statNumber: "137,673,088",
            statName: "social impressions",
          },
        ],
        gallery: [
          {
            url: "/activations/proactiv-pop-this-not-that/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/proactiv-pop-this-not-that/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/proactiv-pop-this-not-that/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/proactiv-pop-this-not-that/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/proactiv-pop-this-not-that/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/proactiv-pop-this-not-that/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/proactiv-pop-this-not-that/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/proactiv-pop-this-not-that/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/proactiv-pop-this-not-that/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/proactiv-pop-this-not-that/9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/proactiv-pop-this-not-that/10.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/proactiv-pop-this-not-that/11.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/harrys-bartershop/thumb.png",
        subsection:"Pop Ups",
        path: "/activations/bartershop/",
        client: "Harry's",
        projectName: "Bartershop",
        type: "activations",
        legacyProject: true,
        description:
          "Working in collaboration with design firm Partners & Spade, TH fabricated and produced an immersive pop-up shop for Harry’s, situated in NYC’s SoHo district. The space was transformed into a modern bathroom environment, where bathrobe-clad retail staff invited shoppers inside to trade in their old razors and shave products for Harry’s equivalents. The pop-up kicked off with a launch event, where press/media attendees were served bathtub gin cocktails, inspired by the brand’s face wash, and personal-sized Domino’s pizzas. A social photo booth captured attendees posing with oversized Harry’s razor props, and on-site embroiderers created individual custom robes as a premium takeaway for guests.",
        statBar: [
          {
            statNumber: "6",
            statName: "activation days",
          },
          {
            statNumber: "1",
            statName: "press/media event",
          },
          {
            statNumber: "176,872",
            statName: "visual impressions",
          },
          {
            statNumber: "515",
            statName: "trades in store",
          },
        ],
        gallery: [
          {
            url: "/activations/harrys-bartershop/harrys-bartershop_1.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/harrys-bartershop/harrys-bartershop_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/harrys-bartershop/harrys-bartershop_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/harrys-bartershop/harrys-bartershop_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/harrys-bartershop/harrys-bartershop_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/harrys-bartershop/harrys-bartershop_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/harrys-bartershop/harrys-bartershop_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/harrys-bartershop/harrys-bartershop_8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/harrys-bartershop/harrys-bartershop_9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/harrys-bartershop/harrys-bartershop_10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/harrys-bartershop/harrys-bartershop_11.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/harrys-bartershop/harrys-bartershop_12.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/kohls-kohls-home-launch/thumb.png",
        subsection:"Pop Ups",
        path: "/activations/kohls-kohls-home-launch/",
        client: "Kohl's",
        projectName: "Kohl's Home Launch",
        type: "activations",
        legacyProject: false,
        description:
          `<p>Celebrating Kohl's relaunch of "Home for Everyone," TH was tasked with developing a comprehensive 360 integrated campaign aimed at inspiring consumers to decorate their homes with Kohl's products. This multi-channel activation featured a digital experience allowing hundreds of thousands of people to browse the collection, get inspired by influencer curations and create vision boards of their favorite stylish products at affordable prices and with the chance to win them all.</p>
          <p>Our campaign also featured a dynamic pop-up event showcasing Kohl's home decor collection. This one day experience drove earned media, was the backdrop for 60 influencers to organic create content and live streams for social impressions, and build brand awareness with over 1,000 new consumers data collected.</p>`,
        gallery: [
          {
            url: "https://player.vimeo.com/video/933884091?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
            type: "vimeo",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/kohls-kohls-home-launch/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/kohls-kohls-home-launch/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/kohls-kohls-home-launch/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/kohls-kohls-home-launch/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/kohls-kohls-home-launch/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/kohls-kohls-home-launch/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/kohls-kohls-home-launch/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/kohls-kohls-home-launch/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/kohls-kohls-home-launch/9.png",
            alt: "",
            sizeClass: "half",
          }

        ],
      },
      {
        thumbnailUrl: "/activations/becca-bright-eyes/thumb.png",
        subsection:"Pop Ups",
        path: "/activations/becca-bright-eyes/",
        client: "Becca",
        projectName: "Bright Eyes",
        type: "activations",
        legacyProject: false,
        description:
          `<p>To celebrate the launch of three new product lines, Becca was looking for a visually unique event experience. They wanted a space that would reflect their bright, uplifting brand messaging and provide a space for media and consumers to interact, take photos and experience the products.</p>
          <p>TH created an environment to host the press and consumer events with multiple interactive touch points. Three interactive rooms showcased the featured products: I'm Cool (Eye-Primer), Disco Nap (Concealer), and Blur Bae (Eye-brightening powder), each came to life through their own individual fun, photo-ready experience.</p>`,
        gallery: [
          {
            url: "/activations/becca-bright-eyes/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/becca-bright-eyes/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/becca-bright-eyes/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/becca-bright-eyes/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/becca-bright-eyes/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/becca-bright-eyes/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/becca-bright-eyes/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/becca-bright-eyes/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/becca-bright-eyes/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/becca-bright-eyes/9.png",
            alt: "",
            sizeClass: "half",
          }
        ],
      },
      {
        thumbnailUrl: "/activations/ole-henriksen-glow-cycle-tour/thumb.png",
        subsection:"Mobile Tours",
        path: "/activations/ole-henriksen-glow-cycle-tour/",
        client: "Ole Henriksen",
        projectName: "Glow Cycle Tour",
        type: "activations",
        legacyProject: false,
        description:
          `<p>Ole Henriksen expanded its US market reach making its full product line available at Ulta. To launch this exciting partnership, Ole Henriksen partnered with TH Experiential to embark on a coast-to-coast three-stop mobile Ole Glow tour, popping-in at Ulta's in Los Angeles, Miami and Austin to spread the Ole joy. </p>
          <p>The Glow Cycle tour significantly elevated brand visibility and achieved remarkable results including 100% sales conversion for all guests at the activation and establishing a successful inaugural partnership between Ole Henriksen and Ulta.</p>`,
        gallery: [
          {
            url: "/activations/ole-henriksen-glow-cycle-tour/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/ole-henriksen-glow-cycle-tour/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/ole-henriksen-glow-cycle-tour/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/ole-henriksen-glow-cycle-tour/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ole-henriksen-glow-cycle-tour/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ole-henriksen-glow-cycle-tour/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ole-henriksen-glow-cycle-tour/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/ole-henriksen-glow-cycle-tour/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ole-henriksen-glow-cycle-tour/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/ole-henriksen-glow-cycle-tour/9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/ole-henriksen-glow-cycle-tour/10.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ole-henriksen-glow-cycle-tour/11.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ole-henriksen-glow-cycle-tour/12.png",
            alt: "",
            sizeClass: "onethird",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/dyson-supersonic-lab/thumb.png",
        subsection:"Mobile Tours",
        path: "/activations/dyson-supersonic-lab/",
        client: "Dyson",
        projectName: "Supersonic Lab",
        type: "activations",
        legacyProject: true,
        description:
          "Getting the only hair dryer in the world that took 103 engineers and stylists four years to create into the hands of people across the country was Dyson’s mission for it’s Supersonic product launch. TH partnered with the iconic British brand to design and engineer a mobile Supersonic Lab experience that would bring Dyson innovation to nine cities across the country. The Lab brought a mobile hair salon experience to the people, executing over 1,179 blow-outs, 9,491 product trials, and generating $29,490 in sales. The ten-stop tour garnered extensive exposure for the brand and its entrance into the personal care market with over 4,858,225 visual impressions. Guest’s Dyson moments were captured through an onsite photo booth and generated over 128,000 social impressions.",
        statBar: [
          {
            statNumber: "9",
            statName: "markets",
          },
          {
            statNumber: "12",
            statName: "activation days",
          },
          {
            statNumber: "1,179",
            statName: "total number of services",
          },
          {
            statNumber: "9,419",
            statName: "product trials",
          },
          {
            statNumber: "4,858,225",
            statName: "visual impressions",
          },
          {
            statNumber: "127,716",
            statName: "social impressions",
          },
        ],
        gallery: [
          {
            url: "/activations/dyson-supersonic-lab/dyson_supersonic_1.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/dyson-supersonic-lab/dyson_supersonic_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/dyson-supersonic-lab/dyson_supersonic_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/dyson-supersonic-lab/dyson_supersonic_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/dyson-supersonic-lab/dyson_supersonic_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/dyson-supersonic-lab/dyson_supersonic_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/dyson-supersonic-lab/dyson_supersonic_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/dyson-supersonic-lab/dyson_supersonic_8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/dyson-supersonic-lab/dyson_supersonic_9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/dyson-supersonic-lab/dyson_supersonic_10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/dyson-supersonic-lab/dyson_supersonic_11.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/dyson-supersonic-lab/dyson_supersonic_12.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/narcan-ready-to-rescue/thumb.png",
        subsection:"Mobile Tours",
        path: "/activations/narcan-ready-to-rescue/",
        client: "Narcan",
        projectName: "Ready To Rescue",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH designed and built annual mobile tours for Narcan including glass trucks, and the Narcan House, unique experiences combining the educational value of NARCAN<sup>®</sup> Nasal Spray awareness with the excitement of a football tailgate.</p>
          <p>The activations featured popular tailgate games, and engaging activities to attract and captivate passersby. Visitors could explore educational spaces dedicated to learning about NARCAN<sup>®</sup> Nasal Spray and its life saving benefits.</p>
          <p>This mobile Narcan Glass House traveled across the country, stopping at universities coast to coast with football legend Emmitt Smith, to effectively engage its target audience and deliver its ready to rescue message.</p>`,
          gallery: [
            {
                url: "https://player.vimeo.com/video/1047632634?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
                type: "vimeo",
                alt: "",
                sizeClass: "full"
          },
          {
            url: "/activations/narcan-ready-to-rescue/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/narcan-ready-to-rescue/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/narcan-ready-to-rescue/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/narcan-ready-to-rescue/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/narcan-ready-to-rescue/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/narcan-ready-to-rescue/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/narcan-ready-to-rescue/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/narcan-ready-to-rescue/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/narcan-ready-to-rescue/9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/narcan-ready-to-rescue/10.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/narcan-ready-to-rescue/11.png",
            alt: "",
            sizeClass: "twothird",
          }
        ],
      },
      {
        thumbnailUrl: "/activations/elemis-mobile-pop-up/thumb.png",
        subsection:"Mobile Tours",
        path: "/activations/elemis-mobile-pop-up/",
        client: "Elemis",
        projectName: "Mobile Pop Up",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH Experiential designed and produced a mobile pop-up tour, immersing consumers in the world of ELEMIS' collagen product line. Within a mere 16 hours, an impressive 2,700 guests engaged with the pop-up, getting 120,000 sets of eyes on the brand in NYC alone.</p>
          <p>An exclusive influencer and press session amplified the activation's impact, extending its reach to the realm of social media, while our thoughtfully crafted mobile setup encouraged organic content creation, offering five distinct touchpoints for guests to share their experiences. From oversized products to floral installations, branded smoothie catering, mirror mantras, and even a bespoke British telephone booth, every detail was designed to leave a lasting impression.</p>`,
        gallery: [
          {
            url: "/activations/elemis-mobile-pop-up/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/elemis-mobile-pop-up/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/elemis-mobile-pop-up/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/elemis-mobile-pop-up/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/elemis-mobile-pop-up/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/elemis-mobile-pop-up/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/elemis-mobile-pop-up/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/elemis-mobile-pop-up/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/elemis-mobile-pop-up/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/elemis-mobile-pop-up/9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/elemis-mobile-pop-up/10.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/elemis-mobile-pop-up/11.png",
            alt: "",
            sizeClass: "twothird",
          }
        ],
      },
      {
        thumbnailUrl: "/activations/plated-road-to-discovery/thumb.png",
        subsection:"Mobile Tours",
        path: "/activations/plated-road-to-discovery/",
        client: "Plated",
        projectName: "Road To Discovery",
        type: "activations",
        legacyProject: true,
        description:
          "To launch their product to change people’s perceptions about cooking, Plated partnered with TH on a mission to help people across the country discover new ingredients, recipes and techniques that would inspire them to cooking. To connect with new audiences TH created a cross-country tour by way of a retrofitted 1970s Airstream trailer with experiences designed to help them cook more and live better. At stops, guests could get personalized spice and recipe recommendations and see live cooking demonstrations showing how simple and convenient meals at home can be. Plated integrated several partners that reflect the values of quality and discovery such as Mastercard, Pandora, McCormick Spices, S. Pellegrino, Delonghi, Laurel & Wolf, Food & Wine Magazine. Sponsor’s products were woven into the existing footprint, providing engaging touchpoints that amplified the tour experience.",
        statBar: [
          {
            statNumber: "17",
            statName: "activation days",
          },
          {
            statNumber: "9",
            statName: "markets",
          },
          {
            statNumber: "97,340,318",
            statName: "press/media impressions",
          },
          {
            statNumber: "13,400,000",
            statName: "social media impressions",
          },
          {
            statNumber: "16,064",
            statName: "unique engagements",
          },
          {
            statNumber: "3,200,728",
            statName: "visual impressions",
          },
        ],
        gallery: [
          {
            url: "/activations/plated-road-to-discovery/road-to-discovery_1.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/plated-road-to-discovery/road-to-discovery_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/plated-road-to-discovery/road-to-discovery_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/plated-road-to-discovery/road-to-discovery_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/plated-road-to-discovery/road-to-discovery_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/plated-road-to-discovery/road-to-discovery_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/plated-road-to-discovery/road-to-discovery_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/plated-road-to-discovery/road-to-discovery_8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/plated-road-to-discovery/road-to-discovery_9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/plated-road-to-discovery/road-to-discovery_10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/plated-road-to-discovery/road-to-discovery_11.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/plated-road-to-discovery/road-to-discovery_12.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/blue-apron-unboxed-on-wheels/thumb.png",
        subsection:"Mobile Tours",
        path: "/activations/blue-apron-unboxed-on-wheels/",
        client: "Blue Apron",
        projectName: "Unboxed On Wheels",
        type: "activations",
        legacyProject: false,
        description:
          `<p>Fabricate and convert a shipping container to produce a custom experience with four distinct rooms all showcasing a part of the food journey.</p>
          <p>Design and install all consumer touchpoints including, coloring book wall for consumers to draw on the walls, hanging fruit backdrop with photo sharing experience, test kitchen with food infused scents, and custom photo wall with crowd sourced imagery from real Blue Apron customers.</p>
          
          <p>Product sampling where Blue Apron spices could be added as popcorn flavoring. Coordinate tour logistics including permitting and stop schedules Facilitate giveaways of water bottles, lip balms, sunglasses, and more with proof of social sharing with specified hashtag.</p>`,
        gallery: [
          {
            url: "/activations/blue-apron-unboxed-on-wheels/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/blue-apron-unboxed-on-wheels/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/blue-apron-unboxed-on-wheels/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/blue-apron-unboxed-on-wheels/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/blue-apron-unboxed-on-wheels/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/blue-apron-unboxed-on-wheels/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/blue-apron-unboxed-on-wheels/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/blue-apron-unboxed-on-wheels/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/blue-apron-unboxed-on-wheels/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/blue-apron-unboxed-on-wheels/9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/blue-apron-unboxed-on-wheels/10.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/blue-apron-unboxed-on-wheels/11.png",
            alt: "",
            sizeClass: "twothird",
          },
          
        ],
      },
      {
        thumbnailUrl: "/activations/benefit-brow-bus/thumb.png",
        subsection:"Mobile Tours",
        path: "/activations/benefit-brow-bus/",
        client: "Benefit",
        projectName: "Brow Bus",
        type: "activations",
        legacyProject: true,
        description:
          "TH executed a two-market mobile tour with the goal of driving trial and awareness of Benefit Cosmetics’ signature brow waxing services. Mimicking Benefit Brow Bar salons across the country, the interior of a 1970s Airstream was transformed to facilitate six service stations with the exterior displaying brand creative and a social call-to-action. Influencer talent was brought in to help promote the campaign, including Natalie Suarez from Natalie Off Duty and Bryce Gruber from Luxury Spot. After making multiple stops in New York City to service press/media attendees, the brand allowed its fans to choose the second city, asking its followers to vote for the next location. The final stop? Chicago’s Pioneer Court.",
        statBar: [
          {
            statNumber: "3",
            statName: "activation days",
          },
          {
            statNumber: "755+",
            statName: "@Benefit beauty tags",
          },
          {
            statNumber: "74,880",
            statName: "on-the-road impressions",
          },
          {
            statNumber: "6,000",
            statName: "samples distributed",
          },
          {
            statNumber: "768,625",
            statName: "visual impressions",
          },
        ],
        gallery: [
          {
            url: "/activations/benefit-brow-bus/brow-bus_1.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/benefit-brow-bus/brow-bus_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/benefit-brow-bus/brow-bus_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/benefit-brow-bus/brow-bus_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/benefit-brow-bus/brow-bus_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/benefit-brow-bus/brow-bus_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/benefit-brow-bus/brow-bus_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/benefit-brow-bus/brow-bus_8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/benefit-brow-bus/brow-bus_9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/benefit-brow-bus/brow-bus_10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/benefit-brow-bus/brow-bus_11.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/benefit-brow-bus/brow-bus_12.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/birchbox-road-trip/thumb.png",
        subsection:"Mobile Tours",
        path: "/activations/birchbox-road-trip/",
        client: "Birchbox",
        projectName: "Road Trip",
        type: "activations",
        legacyProject: true,
        description:
          "TH teamed up with Birchbox to launch a first-of-its-kind tour for the brand, bringing the company’s vast array of beauty products and services into a pop-up shop experience. Launching in Atlanta, the Road Trip invited consumers in to browse a curated assortment of makeup, skincare and hair products and purchase full-sized items at both the men and women’s temporary retail stores. A BYOB (Build Your Own Box) station offered customers an engaging way to discover and test new products by choosing up to five samples. On the roof deck of the steel containers, attendees indulged in free services, including haircuts for men and manicures for women. Special guest appearances included the cast of Empire, The Real Housewives of Atlanta’s Cynthia Bailey and D’Angelo Russell of the Los Angeles Lakers.",
        statBar: [
          {
            statNumber: "9",
            statName: "activation days",
          },
          {
            statNumber: "3",
            statName: "markets",
          },
          {
            statNumber: "730,000,000",
            statName: "press/social media impressions",
          },
          {
            statNumber: "607,750",
            statName: "visual impressions",
          },
        ],
        gallery: [
          {
            url: "/activations/birchbox-road-trip/road-trip_1.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/birchbox-road-trip/road-trip_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/birchbox-road-trip/road-trip_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/birchbox-road-trip/road-trip_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/birchbox-road-trip/road-trip_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/birchbox-road-trip/road-trip_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/birchbox-road-trip/road-trip_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/birchbox-road-trip/road-trip_8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/birchbox-road-trip/road-trip_9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/birchbox-road-trip/road-trip_10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/birchbox-road-trip/road-trip_11.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/birchbox-road-trip/road-trip_12.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/kohls-build-a-list/thumb.png",
        subsection:"Mobile Tours",
        path: "/activations/kohls-build-a-list/",
        client: "Kohl's",
        projectName: "Build-A-List",
        type: "activations",
        legacyProject: true,
        description:
          "We partnered with Kohl’s on a 360-online to offline campaign featuring a custom digital experience and an in person pop-up. Kohl’s Build-A-List microsite enabled consumers to curate and personalize their Holiday Wishlist to share with friends, family and on social media by tagging @Kohls and #BuildAListSweepstakes for a chance to win $250 Kohl’s Cash.<br/><br/>This 360 digital activation includes organic social, paid social, influencer marketing, email, SMS, ecommerce and in-person events for maximum reach and results.",
        gallery: [
          {
            url: "/activations/kohls-build-a-list/1.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/kohls-build-a-list/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/kohls-build-a-list/3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/kohls-build-a-list/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/kohls-build-a-list/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/kohls-build-a-list/6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/kohls-build-a-list/7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/kohls-build-a-list/8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/kohls-build-a-list/9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/kohls-build-a-list/10.png",
            alt: "",
            sizeClass: "half",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/peloton-grassroots-tour/thumb.png",
        subsection:"Mobile Tours",
        path: "/activations/peloton-grassroots-tour/",
        client: "Peloton",
        projectName: "Grassroots Tour",
        type: "activations",
        legacyProject: true,
        description:
          "TH teamed up with Peloton to showcase their unique, interactive fitness solution direct to consumers by scouting and securing locations for a cross-country mobile tour. The tour stopped by nine locations throughout the U.S. in markets where Peloton does not currently have showrooms, including footprints at larger events including Wanderlust Festival, San Francisco Marathon, Tour of Utah and more. TH handled all venue permitting, tour and security management, and event logistics on-site to bring the at-home rider experience closer to the ever-expanding Peloton community.",
        gallery: [
          {
            url: "/activations/peloton-grassroots-tour/grassroots-tour_1.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/peloton-grassroots-tour/grassroots-tour_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/peloton-grassroots-tour/grassroots-tour_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/peloton-grassroots-tour/grassroots-tour_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/peloton-grassroots-tour/grassroots-tour_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/peloton-grassroots-tour/grassroots-tour_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/peloton-grassroots-tour/grassroots-tour_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/peloton-grassroots-tour/grassroots-tour_8.png",
            alt: "",
            sizeClass: "onethird",
          }
        ],
      },
      {
        thumbnailUrl: "/activations/bath-body-works-mothers-day/thumb.png",
        subsection:"Mobile Tours",
        path: "/activations/bath-body-works-mothers-day/",
        client: "Bath & Body Works",
        projectName: "Mother’s Day",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH created a buzzworthy Mother’s Day campaign for Bath & Body Works (BBW) in the center of Chicago. A BBW-branded van with a local influencer hosted DIY paper floral crafting sessions using BBW products, fostering brand affinity and driving sales. </p>

<p>Exclusive online content built anticipation, and “golden ticket” surprise gift bouquets drove traffic. This activation solidified BBW as the go-to destination for celebrating Chicago moms.</p>`,
        
        gallery: [
          {
            url: "/activations/bath-body-works-mothers-day/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/bath-body-works-mothers-day/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/bath-body-works-mothers-day/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/bath-body-works-mothers-day/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/bath-body-works-mothers-day/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/bath-body-works-mothers-day/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/bath-body-works-mothers-day/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/bath-body-works-mothers-day/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/bath-body-works-mothers-day/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/bath-body-works-mothers-day/9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/bath-body-works-mothers-day/10.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/bath-body-works-mothers-day/11.png",
            alt: "",
            sizeClass: "twothird",
          }
          
        ],
      },
      {
        thumbnailUrl: "/activations/olly-winter-wellness/thumb.png",
        subsection:"Mobile Tours",
        path: "/activations/olly-winter-wellness/",
        client: "Olly",
        projectName: "Winter Wellness Event",
        type: "activations",
        legacyProject: true,
        description:
          "To highlight OLLY’s holistic approach to wellness and launch the new Immunity supplements, we designed a Winter Wellness Cafe pop up at Central Park, NYC. The Olly Cafe Truck served hot cocoa and matcha beverages, sampled Olly products and featured branded photo-ops. Olly Brand Ambassador, Rebel Wilson, made an appearance at the Cafe to share with press the wellness tips that contributed to her documented weight-loss, including walking. Attendees were then invited to join her on a walk through Central Park, stopping for photo ops and being surprised on the trail with an Olly Hydration Station serving Olly Immunity drinks.",
        gallery: [
          {
            url: "/activations/olly-winter-wellness/olly_1.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/olly-winter-wellness/olly_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/olly-winter-wellness/olly_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/olly-winter-wellness/olly_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/olly-winter-wellness/olly_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/olly-winter-wellness/olly_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/olly-winter-wellness/olly_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/olly-winter-wellness/olly_8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/olly-winter-wellness/olly_9.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/olly-winter-wellness/olly_10.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/cetaphil-malibu-skinlabs/thumb.png",
        subsection:"Press and Influencer Events",
        path: "/activations/cetaphil-malibu-skinlabs/",
        client: "Cetaphil",
        projectName: "Malibu Skinlabs",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH transformed a beachfront villa in Malibu into a two-day "Skinlabs" global influencer event, hosting over 120 global creators and media to explore the science behind Cetaphil's products through engaging, multi-sensory activations. From testing hydration levels in the Skin Lab to diving deep into skincare with VR in the Tech Lab, each activity offered a unique opportunity for influencers to learn about Cetaphil’s formulas and personalize their skincare routine using AI and AR tech.&nbsp;</p>
          <p>TH designed and developed the entire experience, launching the rebrand while maximizing opportunities for influencers to create and share content across their platforms.</p>`,
          gallery: [
            {
                url: "https://player.vimeo.com/video/1048682936?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
                type: "vimeo",
                alt: "",
                sizeClass: "full"
          },
            {
                url: "/activations/cetaphil-malibu-skinlabs/1.png",
                alt: "",
                sizeClass: "half",
            },
            {
                url: "/activations/cetaphil-malibu-skinlabs/2.png",
                alt: "",
                sizeClass: "half",
            },
            {
                url: "/activations/cetaphil-malibu-skinlabs/3.gif",
                alt: "",
                sizeClass: "onethird",
            },
            {
                url: "/activations/cetaphil-malibu-skinlabs/4.png",
                alt: "",
                sizeClass: "onethird",
            },
            {
                url: "/activations/cetaphil-malibu-skinlabs/5.png",
                alt: "",
                sizeClass: "onethird",
            },
            {
                url: "/activations/cetaphil-malibu-skinlabs/6.png",
                alt: "",
                sizeClass: "twothird",
            },
            {
                url: "/activations/cetaphil-malibu-skinlabs/7.gif",
                alt: "",
                sizeClass: "onethird",
            },
            {
                url: "/activations/cetaphil-malibu-skinlabs/8.gif",
                alt: "",
                sizeClass: "half",
            },
            {
                url: "/activations/cetaphil-malibu-skinlabs/9.png",
                alt: "",
                sizeClass: "half",
            },
            {
                url: "/activations/cetaphil-malibu-skinlabs/10.png",
                alt: "",
                sizeClass: "onethird",
            },
            {
                url: "/activations/cetaphil-malibu-skinlabs/11.png",
                alt: "",
                sizeClass: "twothird",
            }
          
        ],
      },
      {
        thumbnailUrl: "/activations/soap-and-glory-short-lead/thumb.png",
        subsection:"Press and Influencer Events",
        path: "/activations/soap-and-glory-short-lead/",
        client: "Soap&Glory",
        projectName: "Short Lead Media Event",
        type: "activations",
        legacyProject: true,
        description:
          "TH partnered with MML and Soap & Glory to host an evening of glam and bubbles at La Sirena in New York City in celebration of Soap and Glory’s new product launches. Editors and media gathered to enjoy a variety of exclusive curated experiences including a custom champagne bar with bubbles on tap, oversized vanity with all of the latest Soap & Glory cosmetics, pink tiled shower installation complete with scented bubbles and a bath product display and a custom bathtub photo-op surrounded by suspended bath bombs. Additional pink décor and product displays were featured on each wall of the venue and S&G-inspired passed hors d’oeuvres and spirits were served throughout the night.",
        statBar: [
          {
            statNumber: "9",
            statName: "markets",
          },
          {
            statNumber: "12",
            statName: "activation days",
          },
          {
            statNumber: "1,179",
            statName: "total number of services",
          },
          {
            statNumber: "9,419",
            statName: "product trials",
          },
          {
            statNumber: "4,858,225",
            statName: "visual impressions",
          },
          {
            statNumber: "127,716",
            statName: "social impressions",
          },
        ],
        gallery: [
          {
            url: "/activations/soap-and-glory-short-lead/soap-glory-short-lead_1.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/soap-and-glory-short-lead/soap-glory-short-lead_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/soap-and-glory-short-lead/soap-glory-short-lead_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/soap-and-glory-short-lead/soap-glory-short-lead_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/soap-and-glory-short-lead/soap-glory-short-lead_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/soap-and-glory-short-lead/soap-glory-short-lead_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/soap-and-glory-short-lead/soap-glory-short-lead_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/soap-and-glory-short-lead/soap-glory-short-lead_8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/soap-and-glory-short-lead/soap-glory-short-lead_9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/soap-and-glory-short-lead/soap-glory-short-lead_10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/soap-and-glory-short-lead/soap-glory-short-lead_11.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/soap-and-glory-short-lead/soap-glory-short-lead_12.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/kiss-new-product-launch/thumb.png",
        subsection:"Press and Influencer Events",
        path: "/activations/kiss-new-product-launch/",
        client: "Kiss",
        projectName: "New Product Launch Media Event",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH partnered with KISS Products to design and execute a pop-up event for press + media to showcase their four new product lines: Gel Fantasy Allure, imPRESS Premium, The Muses, & Falsies. </p>
          <p>The pop-up space was fully branded, immersing guests in the KISS brand and the new products, encouraging organic photo opps and product trial. Guests also enjoyed a custom designed immersive video projection experience that transported press and influencers through the launches, their innovative formulations and benefits.</p>`,
          gallery: [
            {
                url: "https://player.vimeo.com/video/878443818?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
                type: "vimeo",
                alt: "",
                sizeClass: "full"
          },
          {
            url: "/activations/kiss-new-product-launch/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/kiss-new-product-launch/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/kiss-new-product-launch/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/kiss-new-product-launch/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/kiss-new-product-launch/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/kiss-new-product-launch/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/kiss-new-product-launch/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/kiss-new-product-launch/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/kiss-new-product-launch/9.png",
            alt: "",
            sizeClass: "half",
          }
        ],
      },
      {
        thumbnailUrl: "/activations/ghd-x-briogeo-launch/thumb.png",
        subsection:"Press and Influencer Events",
        path: "/activations/ghd-x-briogeo-launch/",
        client: "GHD X BRIOGEO",
        projectName: "Launch",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH Experiential teamed up with Briogeo and GHD to host an exclusive press and influencer launch party celebrating hair naturally styled by Briogeo and powered by GHD. </p>

          <p>Kicking off with a pro master classes, LA press, and influencers experienced the merging of all-natural hair products with innovative styling tools through one-on-one styling sessions and 360 immersive photo ops.</p>`,
          gallery: [
            {
                url: "https://player.vimeo.com/video/1048690161?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
                type: "vimeo",
                alt: "",
                sizeClass: "full"
          },
          {
            url: "/activations/ghd-x-briogeo-launch/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/ghd-x-briogeo-launch/2.gif",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/ghd-x-briogeo-launch/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ghd-x-briogeo-launch/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ghd-x-briogeo-launch/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ghd-x-briogeo-launch/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/ghd-x-briogeo-launch/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ghd-x-briogeo-launch/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/ghd-x-briogeo-launch/9.png",
            alt: "",
            sizeClass: "half",
          }
        ],
      },
      {
        thumbnailUrl: "/activations/covergirl-pop-up/thumb.png",
        subsection:"Press and Influencer Events",
        path: "/activations/covergirl-pop-up/",
        client: "Covergirl",
        projectName: "Pop-Up",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH and CoverGirl partnered to launch YUMMY PLUMPER, the brand’s first-to-market cooling and plumping lip gloss, with the shared goal to expand the data and metrics that experiential can deliver against strategic KPIs.</p>

<p>We transformed the iconic High Line Nine Gallery into its first-ever experiential activation—an Ice Castle with mobile interactivity and touchpoints that unlocked exclusive samples and gifts. This mutually beneficial exchange between the brand and consumers resulted in:</p>

<ul>
  <li>99% of attendees gave the brand their data</li>
  <li>79% of guests shared organic content on social</li>
  <li>Boosted NPS and purchase intent</li>
  <li>Gathered product feedback and insights</li>
</ul>

<p>Even with temperatures at a frosty 16 degrees, 227 influencers attended for an exclusive preview, and 1,311 beauty lovers lined up the next day, proving that experiential can take you from impressions to impact.</p>`,
gallery: [
  {
      url: "https://player.vimeo.com/video/1053030713?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
      type: "vimeo",
      alt: "",
      sizeClass: "full",
    },
          {
            url: "/activations/covergirl-pop-up/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/covergirl-pop-up/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/covergirl-pop-up/3.avif",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/covergirl-pop-up/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/covergirl-pop-up/5.avif",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/covergirl-pop-up/6.avif",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/covergirl-pop-up/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/covergirl-pop-up/8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/covergirl-pop-up/9.avif",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/covergirl-pop-up/10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/covergirl-pop-up/11.avif",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/covergirl-pop-up/12.png",
            alt: "",
            sizeClass: "twothird",
          }
        ],
      },
      {
        thumbnailUrl: "/activations/the-macallan-new-distillery/thumb.png",
        subsection:"Press and Influencer Events",
        path: "/activations/the-macallan-new-distillery/",
        client: "The Macallan",
        projectName: "New Distillery + Edition No.4 Press Event",
        type: "activations",
        legacyProject: true,
        description:
          "To announce the new breathtaking Macallan Speyside distillery and No. 4 edition bottle, TH partnered with M booth to craft an immersive educational campaign that connected press/media and consumers to the heart of Scotland. The premiere for the experience was a private press event in Brooklyn, set in a historic venue that naturally matched the spirit of The Macallan. Inside guests were taken through a sensory-filled journey that featured recreated vignettes from The Macallan estate, sketches and blueprints, and an eye-catching 4D estate guide that let guests move inside of a 360 projection cube. All decor and furniture echoed the brand’s Scottish heritage while acknowledging their new modern direction bringing their brand story to life. To expand our reach to consumers, TH executed the event in NYC’s iconic Grand Central station where passersby could experience the 4D estate guide, pose in front of a branded living wall, and sample product from experts.",
        gallery: [
          {
            url: "/activations/the-macallan-new-distillery/macallan-distillery_1.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/the-macallan-new-distillery/macallan-distillery_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/the-macallan-new-distillery/macallan-distillery_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/the-macallan-new-distillery/macallan-distillery_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/the-macallan-new-distillery/macallan-distillery_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/the-macallan-new-distillery/macallan-distillery_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/the-macallan-new-distillery/macallan-distillery_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/the-macallan-new-distillery/macallan-distillery_8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/the-macallan-new-distillery/macallan-distillery_9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/the-macallan-new-distillery/macallan-distillery_10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/the-macallan-new-distillery/macallan-distillery_11.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/the-macallan-new-distillery/macallan-distillery_12.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/homegoods-house-of-homegoods/thumb.png",
        subsection:"Press and Influencer Events",
        path: "/activations/homegoods-house-of-homegoods/",
        legacyProject:true,
        client: "HomeGoods",
        projectName: "House of HomeGoods",
        type: "activations",
        description:
          "TH partnered with Edelman to bring to life the versatility of the HomeGoods brand through House of HomeGoods—an activation that highlighted immersive interiors and encouraged organic content creation. The activation featured a bookable getaway that offered consumers a chance to discover something new, as each visit was transformed into a new theme. For each theme, TH curated HomeGoods decor, wall treatments and furnishings inspiration showcasing interior design partners that would be a good fit for each genre. Every flip was distinct and different. The themes were completed with photo-worthy environments and unique activities that generated rich and compelling content for HomeGoods.",
        gallery: [
          {
            url: "/activations/homegoods-house-of-homegoods/1.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/homegoods-house-of-homegoods/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/homegoods-house-of-homegoods/3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/homegoods-house-of-homegoods/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/homegoods-house-of-homegoods/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/homegoods-house-of-homegoods/6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/homegoods-house-of-homegoods/7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/homegoods-house-of-homegoods/8.png",
            alt: "",
            sizeClass: "onethird",
          }
        ],
      },
      {
        thumbnailUrl: "/activations/ketchum-jack-and-coke/thumb.png",
        subsection:"Press and Influencer Events",
        path: "/activations/ketchum-jack-and-coke/",
        client: "Ketchum",
        projectName: "Jack & Coke Launch Party",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH helped launch one of the world's most popular bar calls to the ready-to-drink format - Jack Daniel’s & Coke-A-Cola. Our experiential launch strategy kicked off with a surprise and delight moment sending VIP press custom sourced and designed vintage coolers filled with cans of Jack & Coke.</p>
          <p>To further celebrate the union of these two icons, we secured a luxurious LA home to throw the ultimate Jack & Coke house party featuring games, musical performances, food and of course, cans of Jack & Coke. Our design strategy further reinforced storytelling through fully branded interior and exterior moments that doubled as photo opportunities throughout the space.</p>`,
          gallery: [
            {
                url: "https://player.vimeo.com/video/881026329?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
                type: "vimeo",
                alt: "",
                sizeClass: "full"
          },
          {
            url: "/activations/ketchum-jack-and-coke/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/ketchum-jack-and-coke/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/ketchum-jack-and-coke/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ketchum-jack-and-coke/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ketchum-jack-and-coke/5.png",
            alt: "",
            sizeClass: "onethird",
          },   
          {
            url: "/activations/ketchum-jack-and-coke/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/ketchum-jack-and-coke/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ketchum-jack-and-coke/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/ketchum-jack-and-coke/9.png",
            alt: "",
            sizeClass: "half",
          }
        ],
      },
      {
        thumbnailUrl: "/activations/soap-and-glory-mist-you-madly/thumb.png",
        subsection:"Press and Influencer Events",
        path: "/activations/soap-and-glory-mist-you-madly/",
        client: "Soap & Glory",
        projectName: "Mist You Madly",
        type: "activations",
        legacyProject: true,
        description:
          "TH teamed up with Soap & Glory to create an unforgettable launch event for press, media, and influencers inspired by the floral and seductive nature of the latest product line, Mist You Madly. At famed NYC burlesque club, Duane Park, guests were treated to exclusive live aerial entertainment from performers, product trials, light bites, and champagne toasts. Soap & Glory’s signature pink was integrated throughout all vignettes including a custom over-sized vanity, a custom champagne tap via a vintage sink, a luxe chaise lounge for guest photo ops, and a standalone tub to bring the at-home application environment to life. Beauty experts were on hand to talk guests through the product benefits and assist with application of the body lotions, body scrubs, and more.",
        gallery: [
          {
            url: "/activations/soap-and-glory-mist-you-madly/mist-you-madly_1.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/soap-and-glory-mist-you-madly/mist-you-madly_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/soap-and-glory-mist-you-madly/mist-you-madly_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/soap-and-glory-mist-you-madly/mist-you-madly_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/soap-and-glory-mist-you-madly/mist-you-madly_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/soap-and-glory-mist-you-madly/mist-you-madly_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/soap-and-glory-mist-you-madly/mist-you-madly_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/soap-and-glory-mist-you-madly/mist-you-madly_8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/soap-and-glory-mist-you-madly/mist-you-madly_9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/soap-and-glory-mist-you-madly/mist-you-madly_10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/soap-and-glory-mist-you-madly/mist-you-madly_11.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/soap-and-glory-mist-you-madly/mist-you-madly_12.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/young-living-scents-of-self/thumb.png",
        subsection:"Press and Influencer Events",
        path: "/activations/young-living-scents-of-self/",
        client: "Young Living",
        projectName: "Scents of Self",
        type: "activations",
        legacyProject: true,
        description:
          "Like in fashion, wearing essential oils is an expression of your personality and mood. This is the philosophy of Young Living, and TH partnered with them to bring this idea to life for influencers, press and media during New York Fashion Week. Creating a multi-room, sensorial journey through a series of highly shareable spaces allowed guests to explore each scent, it’s “look,” and the emotions it inspires. Each person could individually connect with the product that best expressed their sense of self. Each of the four launching scents featured a unique experience: Lavender oil - an immersive, relaxing, meditative experience featuring 180 degree projections of lavender fields with a call to action to “Take A Breather.” Citrus oil - an interactive full-room citrus wallpaper that encouraged guests to “Be Bold” and add their own color with on-hand art supplies Rose oil - a celebration of empowerment, this room featured famous stories of influential women throughout history via a custom wall mural where guests could add their own motivational story. Peppermint oil - the journey ended with a peaceful peppermint inspired moment where guests were transported into a scenic woodsy photo moment and audio experience with the soothing sounds of nature. Rich in storytelling and filled with content creating moments, The Young Living Scents of Self experience generated over one billion earned media and social impressions.",
        gallery: [
          {
            url: "/activations/young-living-scents-of-self/scents-of-self_1.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/young-living-scents-of-self/scents-of-self_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/young-living-scents-of-self/scents-of-self_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/young-living-scents-of-self/scents-of-self_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/young-living-scents-of-self/scents-of-self_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/young-living-scents-of-self/scents-of-self_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/young-living-scents-of-self/scents-of-self_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/young-living-scents-of-self/scents-of-self_8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/young-living-scents-of-self/scents-of-self_9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/young-living-scents-of-self/scents-of-self_10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/young-living-scents-of-self/scents-of-self_11.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/young-living-scents-of-self/scents-of-self_12.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/alastin-al-fresco-la-press-event/thumb.png",
        subsection:"Press and Influencer Events",
        path: "/activations/alastin-al-fresco-la-press-event/",
        client: "Alastin",
        projectName: "Al Fresco LA Press Event",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH partnered with key dermatologists and medical aestheticians to design a customized menu of Alastin treatments and procedures. Each treatment or procedure was paired with a corresponding Alastin product, with a particular focus on Nectar, a key product.</p>

          <p>The pop-up med spa was exclusive at first, open only to media and influencers to generate buzz and establish industry credibility. Then it was opened to paying consumers and patients of the partner KOLs. This approach helped maximize brand awareness and convert interest into sales.</p>

          <p>To drive interest and attendance at the pop-up med spa, TH offered product trials, giveaways, and content creation opportunities.</p>`,
        gallery: [
          {
            url: "/activations/alastin-al-fresco-la-press-event/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/alastin-al-fresco-la-press-event/1.png",
            alt: "",
            sizeClass: "half",
          },    
          {
            url: "/activations/alastin-al-fresco-la-press-event/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/alastin-al-fresco-la-press-event/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/alastin-al-fresco-la-press-event/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/alastin-al-fresco-la-press-event/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/alastin-al-fresco-la-press-event/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/alastin-al-fresco-la-press-event/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/alastin-al-fresco-la-press-event/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/alastin-al-fresco-la-press-event/9.png",
            alt: "",
            sizeClass: "half",
          }

        ],
      },
      {
        thumbnailUrl: "/activations/wendys-strawberry-fields/thumb.png",
        subsection:"Press and Influencer Events",
        path: "/activations/wendys-strawberry-fields/",
        client: "Wendy's",
        projectName: "Strawberry Fields",
        type: "activations",
        legacyProject: true,
        description:
          "To publicize the launch of Wendy’s new Strawberry Fields Chicken Salad, TH partnered with Ketchum to create the ultimate summer picnic event, welcoming in press and media to the garden-lined rooftop of 620 Loft & Garden in New York for an evening of food, fun and fashion. In true picnic style, guests had the opportunity to participate in vintage lawn games, create their own summer bracelets and enjoy the brand’s new salad while sipping on Wendy’s Strawberry Lemonade. As a digital extension, attendees were encouraged to snap a pic of themselves using the hashtag #NewSaladCollection for a chance to win a Wendy’s-branded bicycle. Molly Sims served as the evening’s host, showcasing her very own Polyvore fashion board, pinned with the hottest fashion trends in line with the salad’s playful summer color palette.",
        statBar: [
          {
            statNumber: "1",
            statName: "activation day",
          },
          {
            statNumber: "65",
            statName: "attendees",
          },
        ],
        gallery: [
          {
            url: "/activations/wendys-strawberry-fields/strawberry-fields_1.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/wendys-strawberry-fields/strawberry-fields_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/wendys-strawberry-fields/strawberry-fields_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/wendys-strawberry-fields/strawberry-fields_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/wendys-strawberry-fields/strawberry-fields_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/wendys-strawberry-fields/strawberry-fields_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/wendys-strawberry-fields/strawberry-fields_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/wendys-strawberry-fields/strawberry-fields_8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/wendys-strawberry-fields/strawberry-fields_9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/wendys-strawberry-fields/strawberry-fields_10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/wendys-strawberry-fields/strawberry-fields_11.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/wendys-strawberry-fields/strawberry-fields_12.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/invesco-qqq-food-and-wine-festival/thumb.png",
        subsection:"Cultural Events",
        path: "/activations/invesco-qqq-food-and-wine-festival/",
        client: "Invesco QQQ",
        projectName: "Food and Wine Festival",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH partnered with Invesco QQQ, the sponsor of the 2024 NYC Food and Wine Festival, to create both an immersive digital kitchen, where consumer competed in timed cooking challenges hosted by a chef hologram and a lounge space with an AI powered recipe generator.</p>

          <p>In the kitchen space, we took engagement to the next level with timed challenges designed to spark creativity and test cooking skills. With exclusive recipes from celebrity chefs, the kitchen was a hub of high-energy excitement, seamlessly blending culinary innovation with awareness for it’s 50,000 attendees around brand’s unique product positioning and offering.</p>`,
          gallery: [
            {
                url: "https://player.vimeo.com/video/1048691311?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
                type: "vimeo",
                alt: "",
                sizeClass: "full"
          },
          {
            url: "/activations/invesco-qqq-food-and-wine-festival/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/invesco-qqq-food-and-wine-festival/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/invesco-qqq-food-and-wine-festival/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/invesco-qqq-food-and-wine-festival/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/invesco-qqq-food-and-wine-festival/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/invesco-qqq-food-and-wine-festival/6.gif",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/invesco-qqq-food-and-wine-festival/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/invesco-qqq-food-and-wine-festival/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/invesco-qqq-food-and-wine-festival/9.gif",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/invesco-qqq-food-and-wine-festival/10.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/invesco-qqq-food-and-wine-festival/11.png",
            alt: "",
            sizeClass: "twothird",
          }
        ],
      },
      {
        thumbnailUrl: "/activations/stanley-a-cooler-cooler-sxsw-launch/thumb.png",
        subsection:"Cultural Events",
        path: "/activations/stanley-a-cooler-cooler-sxsw-launch/",
        client: "Stanley",
        projectName: "A Cooler Cooler SXSW Launch",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH was tasked to organize eleven sponsored picnics courtesy of Stanley 1913, offering hundreds of attendees the chance to participate. This activation introduced the latest Stanley product, "A Cooler Cooler," in a unique pop-up setting, immersing guests in the coolers natural environment. </p>
          <p>The "A Cooler Cooler" picnic seamlessly integrated Stanley's product line palette into the decor providing an Instagrammable picnic experience! Guests enjoyed catering, entertainment, activities, and the opportunity to go home with a personalized embroidered cooler.</p>`,
        gallery: [
            {
                url: "/activations/stanley-a-cooler-cooler-sxsw-launch/hero.png",
                alt: "",
                sizeClass: "full",
              },
              {
                url: "/activations/stanley-a-cooler-cooler-sxsw-launch/1.png",
                alt: "",
                sizeClass: "half",
              },
              {
                url: "/activations/stanley-a-cooler-cooler-sxsw-launch/2.png",
                alt: "",
                sizeClass: "half",
              },
              {
                url: "/activations/stanley-a-cooler-cooler-sxsw-launch/3.png",
                alt: "",
                sizeClass: "onethird",
              },
              {
                url: "/activations/stanley-a-cooler-cooler-sxsw-launch/4.png",
                alt: "",
                sizeClass: "onethird",
              },
              {
                url: "/activations/stanley-a-cooler-cooler-sxsw-launch/5.png",
                alt: "",
                sizeClass: "onethird",
              },
              {
                url: "/activations/stanley-a-cooler-cooler-sxsw-launch/6.gif",
                alt: "",
                sizeClass: "twothird",
              },
              {
                url: "/activations/stanley-a-cooler-cooler-sxsw-launch/7.png",
                alt: "",
                sizeClass: "onethird",
              },
              {
                url: "/activations/stanley-a-cooler-cooler-sxsw-launch/8.png",
                alt: "",
                sizeClass: "half",
              },
              {
                url: "/activations/stanley-a-cooler-cooler-sxsw-launch/9.png",
                alt: "",
                sizeClass: "half",
              },
              {
                url: "/activations/stanley-a-cooler-cooler-sxsw-launch/10.png",
                alt: "",
                sizeClass: "onethird",
              },
              {
                url: "/activations/stanley-a-cooler-cooler-sxsw-launch/11.png",
                alt: "",
                sizeClass: "twothird",
              }
        ],
      },
      {
        thumbnailUrl: "/activations/ibm-and-watson-us-open/thumb.png",
        subsection:"Cultural Events",
        path: "/activations/ibm-and-watson-us-open/",
        client: "IBM & Watson",
        projectName: "US Open",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH completely transformed an existing garage space into a beautiful, hi-tech experience. Guests interacted directly with IBM’s incredible Watson computer system at multiple cognitive photo booths. They spoke directly with Watson and watched as its world-class AI technology analyzed their conversation to create a personal portrait for each.</p>

          <p>They were also able to see how they stacked up to the personalities of some of their favorite tennis stars. Over 4000 guests had an amazing time interacting with one of the most powerful artificial intelligences in the world and took home their own customized portrait.</p>`,
        gallery: [
            {
                url: "/activations/ibm-and-watson-us-open/hero.png",
                alt: "",
                sizeClass: "full",
            },
            {
                url: "/activations/ibm-and-watson-us-open/1.png",
                alt: "",
                sizeClass: "half",
            },
            {
                url: "/activations/ibm-and-watson-us-open/2.png",
                alt: "",
                sizeClass: "half",
            },
            {
                url: "/activations/ibm-and-watson-us-open/3.png",
                alt: "",
                sizeClass: "onethird",
            },
            {
                url: "/activations/ibm-and-watson-us-open/4.png",
                alt: "",
                sizeClass: "onethird",
            },
            {
                url: "/activations/ibm-and-watson-us-open/5.png",
                alt: "",
                sizeClass: "onethird",
            },
            {
                url: "/activations/ibm-and-watson-us-open/6.png",
                alt: "",
                sizeClass: "twothird",
            },
            {
                url: "/activations/ibm-and-watson-us-open/7.png",
                alt: "",
                sizeClass: "onethird",
            }
          
        ],
      },
      {
        thumbnailUrl: "/activations/lafw-an-only-child/thumb.png",
        subsection:"Cultural Events",
        path: "/activations/lafw-an-only-child/",
        client: "LAFW",
        projectName: "An Only Child",
        type: "activations",
        legacyProject: false,
        description:
          `<p>We partnered with LAFW to produce the An Only Child fashion show. The show featured a unique immersive experiential set, inviting guests to view from a “living room” perspective as the designer sought to transport guests back to his childhood experiences.</p>

          <p>We sourced and installed hundreds of vintage sofas, chairs and ottomans to create an over the top living room and with a 360 video projected installation.</p>`,
          gallery: [
            {
                url: "https://player.vimeo.com/video/1048695667?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
                type: "vimeo",
                alt: "",
                sizeClass: "full"
          },
          {
            url: "/activations/lafw-an-only-child/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/lafw-an-only-child/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/lafw-an-only-child/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/lafw-an-only-child/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/lafw-an-only-child/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/lafw-an-only-child/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/lafw-an-only-child/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/lafw-an-only-child/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/lafw-an-only-child/9.png",
            alt: "",
            sizeClass: "half",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/lg-house-of-lg-signature/thumb.png",
        subsection:"Cultural Events",
        path: "/activations/lg-house-of-lg-signature/",
        client: "LG",
        projectName: "House of LG Signature",
        subLabel: "Art Basel",
        type: "activations",
        legacyProject: true,
        description:
          "The “House of LG Signature” was designed to introduce LG’s new Signature Series to a targeted audience of tastemakers and art enthusiasts during a highly-relevant conversational moment, Art Basel. Inviting guests to experience “The Art of Essence” they were guided through a series of immersive, breathtaking experiences designed in collaboration with four renowned artists, each working in a unique medium to highlight key attributes of items from the line. The end experience mirrored the brand’s mix of cutting edge technology and gorgeous aesthetics through a sensorial experience that left an indelible impression and generated word of mouth for the brand with a highly influential demographic.",
        statBar: [
          {
            statNumber: "1,800+",
            statName: "visitors",
          },
          {
            statNumber: "3",
            statName: "activation days",
          },
        ],
        gallery: [
          {
            url: "/activations/lg-house-of-lg-signature/lg_house-of-lg-signature_1.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/lg-house-of-lg-signature/lg_house-of-lg-signature_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/lg-house-of-lg-signature/lg_house-of-lg-signature_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/lg-house-of-lg-signature/lg_house-of-lg-signature_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/lg-house-of-lg-signature/lg_house-of-lg-signature_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/lg-house-of-lg-signature/lg_house-of-lg-signature_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/lg-house-of-lg-signature/lg_house-of-lg-signature_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/lg-house-of-lg-signature/lg_house-of-lg-signature_8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/lg-house-of-lg-signature/lg_house-of-lg-signature_9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/lg-house-of-lg-signature/lg_house-of-lg-signature_10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/lg-house-of-lg-signature/lg_house-of-lg-signature_11.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/lg-house-of-lg-signature/lg_house-of-lg-signature_12.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/lindt-lindor-holiday-magic/thumb.png",
        subsection:"Cultural Events",
        path: "/activations/lindt-lindor-holiday-magic/",
        client: "Lindt Lindor",
        projectName: "Holiday Magic",
        type: "activations",
        legacyProject: false,
        description:
          `<p>Lindt wanted to deliver a sweet moment for holiday travelers and remind them to incorporate a bit of chocolate truffles into their festivities. We designed an airport pop up for Reagan National, where guests could quickly pass thru, get a sweet treat for their journey, create a holiday message to send to the loved ones waiting at their destination, and play to win larger Lindt holiday gifts to share on their arrival.</p>
        <p>The Lindt Layover Activation at Reagan Airport was a standout success, bringing the Lindt brand to life with creativity, precision, and meaningful engagement. This two-day activation combined thoughtful design, generous sampling, and seamless execution to create a premium experience for holiday travelers.</p>`,
        gallery: [
          {
            url: "/activations/lindt-lindor-holiday-magic/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/lindt-lindor-holiday-magic/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/lindt-lindor-holiday-magic/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/lindt-lindor-holiday-magic/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/lindt-lindor-holiday-magic/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/lindt-lindor-holiday-magic/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/lindt-lindor-holiday-magic/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/lindt-lindor-holiday-magic/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/lindt-lindor-holiday-magic/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/lindt-lindor-holiday-magic/9.png",
            alt: "",
            sizeClass: "half",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/sephora-culture-con/thumb.png",
        subsection:"Cultural Events",
        path: "/activations/sephora-culture-con/",
        client: "Sephora",
        projectName: "Culture Con",
        type: "activations",
        legacyProject: false,
        description:
          `<p>As part of their multi-cultural strategy, we popped-up Sephora at Culture Con with an experience that reinforced the brands reputation as a welcoming and inclusive destination, while driving engagement and promoting their product offerings through various interactive touchpoints.</p>

<p>Sephora Beauty Advisors provided touch-ups, allowing guests to experience their brand’s products firsthand. Attendees also had the opportunity to take professional headshots and participate in a spin-the-wheel game for a chance to win Sephora goodie bags, creating an element of surprise and delight.</p>`,
        gallery: [
          {
            url: "/activations/sephora-culture-con/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/sephora-culture-con/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/sephora-culture-con/2.gif",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/sephora-culture-con/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/sephora-culture-con/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/sephora-culture-con/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/sephora-culture-con/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/sephora-culture-con/7.gif",
            alt: "",
            sizeClass: "onethird",
          },

        ],
      },
      {
        thumbnailUrl: "/activations/e-network-race-to-pca/thumb.png",
        subsection:"Cultural Events",
        path: "/activations/e-network-race-to-pca/",
        client: "E!PCA",
        projectName: "Race to PCAs",
        subLabel: "E! People's Choice Awards",
        type: "activations",
        legacyProject: true,
        description:
          "NBC came to TH looking to ignite passions for pop culture across the U.S. for their inaugural broadcast of the People’s Choice Awards on E!. As the only award show that gives the power to the people, TH sought to celebrate each fan’s vote in a way that made it feel special and shareable. Inviting consumers to participate with fun messaging like “Voting Never Tasted So Good,” passersby were drawn in by a modern branded Airstream where they learn about the awards, voted for their favorite pop-culture icon and receive an Instagrammable edible gift. Each voter received a custom decorated donut packaged in E! PCA cartons. Cartons and voter pins were personalized with their choice of nominee. Consumers could share their voting experience on social and build even more support for their favorite celebrity or show. The physical engagement transformed voting into a memorable experience and incentivized consumers to tune in to the People’s Choice Awards live. In the end, TH took the experience to three markets and inspired more than 15,000 people to cote and generated over 2,000,000 million visual impressions for the award show.",
        gallery: [
          {
            url: "/activations/e-network-race-to-pca/race-to-pcas_1.jpg",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/e-network-race-to-pca/race-to-pcas_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/e-network-race-to-pca/race-to-pcas_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/e-network-race-to-pca/race-to-pcas_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/e-network-race-to-pca/race-to-pcas_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/e-network-race-to-pca/race-to-pcas_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/e-network-race-to-pca/race-to-pcas_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/e-network-race-to-pca/race-to-pcas_8.jpg",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/e-network-race-to-pca/race-to-pcas_9.jpg",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/e-network-race-to-pca/race-to-pcas_10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/e-network-race-to-pca/race-to-pcas_11.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/e-network-race-to-pca/race-to-pcas_12.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/activations/keds-womens-day/thumb.png",
        subsection:"Cultural Events",
        path: "/activations/keds-womens-day/",
        client: "Keds",
        projectName: "Women's Day",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH was tasked by Keds to produce a branded truck campaign for one day in New York City, handing out complimentary shoes in celebration of Women’s Equality Day. The day itself commemorates the passing of the 19th Amendment on August 26,1920, when women in the United States were lawfully granted the right to vote.</p>

<p>Throughout the event, onsite brand ambassadors spread the message: “Today is Women’s Equality Day. What about tomorrow?” Pedestrians lined up outside the truck to receive their free pair of Keds, and participants were encouraged to share a picture of themselves using #LadiesFirst and #WomensEqualityDay on Twitter and Instagram.</p>`,
        gallery: [
          {
            url: "/activations/keds-womens-day/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/keds-womens-day/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/keds-womens-day/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/keds-womens-day/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/keds-womens-day/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/keds-womens-day/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/keds-womens-day/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/keds-womens-day/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/keds-womens-day/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/keds-womens-day/9.png",
            alt: "",
            sizeClass: "half",
          }
        ],
      },
      {
        thumbnailUrl: "/activations/sweetarts-follow-your-tart/thumb.png",
        subsection:"Cultural Events",
        path: "/activations/sweetarts-follow-your-tart/",
        client: "Sweetarts",
        projectName: "#FOLLOWYOURTART",
        subLabel: "Vid Con",
        type: "activations",
        legacyProject: false,
        description:
          `<p>Bringing the sweets to VidCon and Comic-Con, we partnered with SweeTARTS to launch their Gummies. We sourced and build-out of 12' glass showroom to house a gummy candy factory, and integration of a Rube-Goldberg-inspired machine that allowed guests to create custom SweeTARTS Gummies based on their unique flavor profiles. The design of colorful, exterior lounge offering attendees a place to relax and play games while attending the conventions.</p>`,
        gallery: [
          {
            url: "/activations/sweetarts-follow-your-tart/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/sweetarts-follow-your-tart/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/sweetarts-follow-your-tart/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/sweetarts-follow-your-tart/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/sweetarts-follow-your-tart/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/sweetarts-follow-your-tart/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/sweetarts-follow-your-tart/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/sweetarts-follow-your-tart/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/sweetarts-follow-your-tart/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/sweetarts-follow-your-tart/9.png",
            alt: "",
            sizeClass: "half",
          } 
        ],
      },
      {
        thumbnailUrl: "/activations/asics-extra-mile/thumb.png",
        subsection:"Cultural Events",
        path: "/activations/asics-extra-mile/",
        client: "ASICS",
        projectName: "Extra Mile",
        subLabel: "NYC Marathon",
        type: "activations",
        legacyProject: false,
        description:
          `<p>In the final year of ASICS being the title sponsor of the New York City Marathon, the brand looked to activate in both a physically and digitally integrated way, all for a good cause. Guests around the world were invited to participate in the ASICS Extra Mile campaign by logging their runs in the Runkeeper app, with each extra mile triggering a donation of running shoes to the charity Girls on the Run. </p>

<p>Outside the marathon finish line, the day prior to race day, a physical manifestation of the online experience was brought to the masses by
inviting consumers to lace up a pair of ASICS and jump on one of five treadmills programmed to a large outdoor digital display with data aggregation on the app that captured all miles ran on the worldwide leaderboard.</p>`,
        gallery: [
          {
            url: "/activations/asics-extra-mile/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/asics-extra-mile/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/asics-extra-mile/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/asics-extra-mile/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/asics-extra-mile/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/asics-extra-mile/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/asics-extra-mile/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/asics-extra-mile/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/asics-extra-mile/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/asics-extra-mile/9.png",
            alt: "",
            sizeClass: "half",
          }
        ],
      },
      {
        thumbnailUrl: "/activations/details-midnight/thumb.png",
        subsection:"Cultural Events",
        path: "/activations/details-midnight/",
        client: "Details",
        projectName: "@Midnight",
        subLabel: "Coachella",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH produced Details@Midnight, the magazine’s epic annual Coachella party. Troops of partygoers were shuttled to a posh private residence filled with desert-inspired decor elements. Colorfully lit teepees and glowing orbs filled the outdoor space as guests enjoyed desert-themed silk-screening t-shirt stations, movie screenings, food trucks and a digital social photo booth. </p>

<p>Specialty cocktails and tequila-infused ice cream were available to guests at two Patron-sponsored bars disbursed among dancing attendees raving to the sounds of Brendan Fallis & Hannah Bronfman, The Knocks and a surprise guest performance by RAC. VIPs and celebrities were chauffeured to the event in Lexus vehicles where they partied poolside in the VIP area.</p>`,
        gallery: [
          {
            url: "/activations/details-midnight/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/details-midnight/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/details-midnight/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/details-midnight/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/details-midnight/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/details-midnight/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/details-midnight/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/details-midnight/7.png",
            alt: "",
            sizeClass: "onethird",
          }
        ],
      },
      {
        thumbnailUrl: "/activations/ibm-hidden-figures-at-ces/thumb.png",
        subsection:"Tradeshows and Conferences",
        path: "/activations/ibm-hidden-figures-at-ces/",
        client: "IBM",
        projectName: "Hidden Figures @ CES",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH created a unique and dramatic moment for IBM at CES at Las Vegas to celebrate the Hidden Figures movie launch.</p>

<p>This custom immersive experience ran for 4 days and showcased IBM's campaign through a mix of technology innovation including video content, augmented reality, holographic projections and directional sound.</p>`,
        gallery: [
          {
            url: "/activations/ibm-hidden-figures-at-ces/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/ibm-hidden-figures-at-ces/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/ibm-hidden-figures-at-ces/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/ibm-hidden-figures-at-ces/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ibm-hidden-figures-at-ces/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ibm-hidden-figures-at-ces/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ibm-hidden-figures-at-ces/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/ibm-hidden-figures-at-ces/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ibm-hidden-figures-at-ces/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/ibm-hidden-figures-at-ces/9.png",
            alt: "",
            sizeClass: "half",
          }
        ],
      },
      {
        thumbnailUrl: "/activations/alastin-aesthetician-advocate-seminars/thumb.png",
        subsection:"Tradeshows and Conferences",
        path: "/activations/alastin-aesthetician-advocate-seminars/",
        client: "Alastin",
        projectName: "Aesthetician Advocate Seminars",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH Experiential partnered with ALASTIN Skincare to develop a series of in-person and virtual summits with the specific strategic objective of leveraging experiential to develop, grow, and evolve their aesthetician community into brand advocates. </p>

<p>The focus of the educational sessions revolved around the understanding of Alastin's exceptional skincare solutions.and included 2-way live participation where guests could scan QR codes and participate from their mobile devices with quizzes, trivia, and submit live feedback. </p>

<p>The event concluded with the reveal of a branded AR filter, allowing attendees to proudly proclaim their newfound status as devoted Alastin Advocates and organically drive UGC.</p>`,
gallery: [
    {
        url: "https://player.vimeo.com/video/967118551?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full"
  },
          {
            url: "/activations/alastin-aesthetician-advocate-seminars/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/alastin-aesthetician-advocate-seminars/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/alastin-aesthetician-advocate-seminars/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/alastin-aesthetician-advocate-seminars/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/alastin-aesthetician-advocate-seminars/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/alastin-aesthetician-advocate-seminars/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/alastin-aesthetician-advocate-seminars/7.gif",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/alastin-aesthetician-advocate-seminars/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/alastin-aesthetician-advocate-seminars/9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/alastin-aesthetician-advocate-seminars/10.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/alastin-aesthetician-advocate-seminars/11.png",
            alt: "",
            sizeClass: "twothird",
          }
        ],
      },
      {
        thumbnailUrl: "/activations/shape-women-run-the-world/thumb.png",
        subsection:"Tradeshows and Conferences",
        path: "/activations/shape-women-run-the-world/",
        client: "Shape",
        projectName: "Women Run The World",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH ignited the passion of Shape conference attendees around female empowerment with a bold, vibrant event environment filled with bright color graphics and organic photo moments throughout the space. </p>

<p>The venue was transformed to create a modular platform for the conference’s array of programming, which included panel talks, influencer appearances, workshops, yoga classes and massage sessions and more. Guests left feeling refreshed, invigorated and ready to turn their passion into purpose and change the world.</p>`,
        gallery: [
          {
            url: "/activations/shape-women-run-the-world/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/shape-women-run-the-world/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/shape-women-run-the-world/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/shape-women-run-the-world/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/shape-women-run-the-world/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/shape-women-run-the-world/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/shape-women-run-the-world/6.png",
            alt: "",
            sizeClass: "twothird",
          }, 
          {     
            url: "/activations/shape-women-run-the-world/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/shape-women-run-the-world/8.png",
            alt: "",
            sizeClass: "half",
          }, 
          {     
            url: "/activations/shape-women-run-the-world/9.png",
            alt: "",
            sizeClass: "half",
          }
        ],
      },
      {
        thumbnailUrl: "/activations/bet-bet-network/thumb.png",
        subsection:"Tradeshows and Conferences",
        path: "/activations/bet-bet-network/",
        client: "BET",
        projectName: "BET NETWORK",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH brought the show to life with a fully propped for the second year in a row, TH partnered with BET Networks for the BET Experience in Los Angeles, CA. Taking over the West Hall of the L.A. Convention Center over the course of the two-day event, TH designed and produced an interactive entrance, which included full stair wraps showcasing event creative and logos of all of the BET properties, branded charging kiosks, overhead signage and 160' of oversize LED walls displaying brand content. </p>

<p>Participants who snapped photos using #BETX could find their pics posted on social aggregation screens lining the center hallways of the venue. As a tribute to Prince, the walls were covered in projected purple rain drops at specific times throughout each day, and attendees were gifted purple swag bags branded with the BET Experience logo as a take-home.</p>`,
        gallery: [
          {
            url: "/activations/bet-bet-network/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/bet-bet-network/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/bet-bet-network/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/bet-bet-network/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/bet-bet-network/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/bet-bet-network/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/bet-bet-network/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/bet-bet-network/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/bet-bet-network/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/bet-bet-network/9.png",
            alt: "",
            sizeClass: "half",
          }
        ],
      },
      {
        thumbnailUrl: "/activations/coursera/thumb.png",
        subsection:"Tradeshows and Conferences",
        path: "/activations/coursera/",
        client: "Coursera",
        projectName: "",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH brought Coursera Connect to the global stage with multi-channel live-streamed keynote presentations, expertly coordinated breakout sessions, and an inspiring awards dinner. </p>

<p>With over 1,100 attendees, Coursera Connect drove impactful discussions, highlighted transformative innovations, and gave exclusive, hands-on access to Coursera’s cutting-edge products, setting a new standard for industry engagement and collaboration.</p>`,
gallery: [
    {
        url: "https://player.vimeo.com/video/1048730084?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full"
  },
            {
                url: "/activations/coursera/1.png",
                alt: "",
                sizeClass: "half",
            },
            {
                url: "/activations/coursera/2.gif",
                alt: "",
                sizeClass: "half",
            },
            {
                url: "/activations/coursera/3.png",
                alt: "",
                sizeClass: "onethird",
            },
            {
                url: "/activations/coursera/4.png",
                alt: "",
                sizeClass: "onethird",
            },
            {
                url: "/activations/coursera/5.png",
                alt: "",
                sizeClass: "onethird",
            },
            {
                url: "/activations/coursera/6.png",
                alt: "",
                sizeClass: "twothird",
            },
            {
                url: "/activations/coursera/7.png",
                alt: "",
                sizeClass: "onethird",
            },
            {
                url: "/activations/coursera/8.png",
                alt: "",
                sizeClass: "half",
            },
            {
                url: "/activations/coursera/9.png",
                alt: "",
                sizeClass: "half",
            }
        ],
      },
      {
        thumbnailUrl: "/activations/nexxus-ulta-flc/thumb.png",
        subsection:"Tradeshows and Conferences",
        path: "/activations/nexxus-ulta-flc/",
        client: "Nexxus",
        projectName: "ULTA FLC",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH Experiential partnered with Unilever's Nexxus Brand Team to create an engaging and educational experience at the Ulta Sales Conference in Las Vegas. We produced an eye-catching booth, "Get Ready With Nexxus," that showcased Nexxus’ science-backed haircare in the place it is most often used - the bathroom.</p>

<p>To further promote storytelling, we designed a branded interactive vanity and shower that doubled as photo worthy product displays. The booth offered Ulta field team members a unique consumer journey inclusive of ingredient powered smoothie stations and content creation moments throughout the space.</p>`,
        gallery: [
            {
                url: "/activations/nexxus-ulta-flc/hero.png",
                alt: "",
                sizeClass: "full",
            },
            {
                url: "/activations/nexxus-ulta-flc/1.png",
                alt: "",
                sizeClass: "half",
            },
            {
                url: "/activations/nexxus-ulta-flc/2.png",
                alt: "",
                sizeClass: "half",
            },
            {
                url: "/activations/nexxus-ulta-flc/3.png",
                alt: "",
                sizeClass: "onethird",
            },
            {
                url: "/activations/nexxus-ulta-flc/4.png",
                alt: "",
                sizeClass: "onethird",
            },
            {
                url: "/activations/nexxus-ulta-flc/5.png",
                alt: "",
                sizeClass: "onethird",
            },
            {
                url: "/activations/nexxus-ulta-flc/6.png",
                alt: "",
                sizeClass: "twothird",
            },
            {
                url: "/activations/nexxus-ulta-flc/7.png",
                alt: "",
                sizeClass: "onethird",
            },
            {
                url: "/activations/nexxus-ulta-flc/8.png",
                alt: "",
                sizeClass: "half",
            },
            {
                url: "/activations/nexxus-ulta-flc/9.png",
                alt: "",
                sizeClass: "half",
            }
          
        ],
      },
      {
        thumbnailUrl: "/activations/caterpillar-tradeshow/thumb.png",
        subsection:"Tradeshows and Conferences",
        path: "/activations/caterpillar-tradeshow/",
        client: "Caterpillar",
        projectName: "Tradeshow",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH partnered with Caterpillar for a five-stop nationwide tour, showcasing Caterpillars at major construction trade shows.</p><p>The experience highlighted Caterpillar's powerful construction machines, inviting attendees to “punch above their weight” and explore the equipment, materials, and innovative projects made possible with their technology. This campaign was designed to connect them closely with their end users and recognize them and the work they do, and encouraging them to create content and share it.</p>`,
        gallery: [
          {
            url: "/activations/caterpillar-tradeshow/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/caterpillar-tradeshow/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/caterpillar-tradeshow/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/caterpillar-tradeshow/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/caterpillar-tradeshow/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/caterpillar-tradeshow/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/caterpillar-tradeshow/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/caterpillar-tradeshow/7.png",
            alt: "",
            sizeClass: "onethird",
          }
          
        ],
      },
      {
        thumbnailUrl: "/activations/ulta-flc/thumb.png",
        subsection:"Tradeshows and Conferences",
        path: "/activations/ulta-flc/",
        client: "Ulta",
        projectName: "FLC",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH partnered with Ulta to unveil the Ulta Beauty Collection at the FLC 2024 conference. To shift the perception of UBC and foster meaningful conversations among field leaders, we created an engaging environment that offered a playful and immersive experience for conference attendees.</p>

<p>The design and interactivity generated excitement for the launch and introduced the branding and product in an impactful way.</p>`,
        gallery: [
          {
            url: "/activations/ulta-flc/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/ulta-flc/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/ulta-flc/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/ulta-flc/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ulta-flc/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ulta-flc/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ulta-flc/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/ulta-flc/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/ulta-flc/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/ulta-flc/9.png",
            alt: "",
            sizeClass: "half",
          }
        ],
      },
      {
        thumbnailUrl: "/activations/bet-networks-bet-experience/thumb.png",
        subsection:"Tradeshows and Conferences",
        path: "/activations/bet-networks-bet-experience/",
        client: "BET Networks",
        projectName: "BET Experience",
        type: "activations",
        legacyProject: false,
        description:
          `<p>We developed a BET Experience designed to showcase BET exclusive shows such as “Being Mary Jane,” “The Game,” and “Real Husbands of Hollywood”.</p>

<p>TH managed the full event concept & production, talent sourcing and training for a 32 person staff team. The activation included the integration of a social media bar where guests were able to print their Instagrams from the experience and share using #IAmABETStar #CentristicStyle.</p>`,
        gallery: [
          {
            url: "/activations/bet-networks-bet-experience/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/bet-networks-bet-experience/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/bet-networks-bet-experience/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/bet-networks-bet-experience/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/bet-networks-bet-experience/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/bet-networks-bet-experience/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/bet-networks-bet-experience/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/bet-networks-bet-experience/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/bet-networks-bet-experience/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/bet-networks-bet-experience/9.png",
            alt: "",
            sizeClass: "half",
          }
        ],
      },
      {
        thumbnailUrl: "/activations/western-cloud-my-cloud/thumb.png",
        subsection:"Tradeshows and Conferences",
        path: "/activations/western-cloud-my-cloud/",
        client: "Western Cloud",
        projectName: "My Cloud",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH produced an immersive My Cloud lounge experience for CES. Utilizing a custom airstream to showcase WD as a brand and their premium products with displays, demos, prizes and fun brand interactions. </p>

<p>Guests entered a tented area with a full scale lounge, complete with free wifi and all the necessities a CES power-attendee would need. They then relaxed, recharged and snapped photos in the cloud chair social photo booth.</p>

<p>During 4 activations days, over 7,500 CES attendees enjoyed a positive and immersive WD brand experience.</p>`,
        gallery: [
          {
            url: "/activations/western-cloud-my-cloud/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/western-cloud-my-cloud/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/western-cloud-my-cloud/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/western-cloud-my-cloud/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/western-cloud-my-cloud/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/western-cloud-my-cloud/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/western-cloud-my-cloud/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/activations/western-cloud-my-cloud/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/western-cloud-my-cloud/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/western-cloud-my-cloud/9.png",
            alt: "",
            sizeClass: "half",
          }
        ],
      },
      {
        thumbnailUrl: "/activations/zappos-pebble-beach-food-and-wine/thumb.png",
        subsection:"Tradeshows and Conferences",
        path: "/activations/zappos-pebble-beach-food-and-wine/",
        client: "Zappos",
        projectName: "Pebble Beach Food & Wine",
        type: "activations",
        legacyProject: false,
        description:
          `<p>We partnered with Zappos to create a BBQ-inspired lounge space featuring  Zappos’ line of products attended by consumers and influencers and celebrities including Drew Barrymore.</p>

    <p>Execution included sourcing furniture and additional decor elements and integrating “create your own dry rub” stations for guests staffed by brand ambassadors and culinary experts.</p>`,
        gallery: [
          {
            url: "/activations/zappos-pebble-beach-food-and-wine/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/zappos-pebble-beach-food-and-wine/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/zappos-pebble-beach-food-and-wine/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/zappos-pebble-beach-food-and-wine/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/zappos-pebble-beach-food-and-wine/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/zappos-pebble-beach-food-and-wine/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/zappos-pebble-beach-food-and-wine/6.png",
            alt: "",
            sizeClass: "onethird",
          },    
          {
            url: "/activations/zappos-pebble-beach-food-and-wine/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/zappos-pebble-beach-food-and-wine/8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/zappos-pebble-beach-food-and-wine/9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/zappos-pebble-beach-food-and-wine/10.png",
            alt: "",
            sizeClass: "half",
          }
          
        ],
      },
      {
        thumbnailUrl: "/activations/murad-ulta-flc/thumb.png",
        subsection:"Tradeshows and Conferences",
        path: "/activations/murad-ulta-flc/",
        client: "Murad",
        projectName: "ULTA FLC",
        type: "activations",
        legacyProject: false,
        description:
          `<p>TH designed and executed a prominent presence at the Ulta Beauty Field Leadership Conference for Murad and highlighting the launch of the new Retinol Youth Renewal serum and eye mask. </p>

<p>The experience was created to showcase the ultimate duet of eye products and with an eye-catching and memorable 3-step educational event experience. This was of high value to guests as they receive a custom skin analysis, personalized routines and samples.</p>`,
        gallery: [
          {
            url: "/activations/murad-ulta-flc/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/activations/murad-ulta-flc/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/murad-ulta-flc/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/activations/murad-ulta-flc/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/murad-ulta-flc/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/murad-ulta-flc/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/activations/murad-ulta-flc/6.png",
            alt: "",
            sizeClass: "twothird",
          },    
          {
            url: "/activations/murad-ulta-flc/7.png",
            alt: "",
            sizeClass: "onethird",
          }
        ],
      }
];
export default activations;