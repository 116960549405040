import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Import scroll images
import scrollImg from '../assets/scroll.avif';
import scroll2x from '../assets/scroll@2x.avif';
import scroll3x from '../assets/scroll@3x.avif';
import scrollMob from '../assets/scroll-mob.avif';
import scrollMob2x from '../assets/scroll-mob@2x.avif';
import scrollMob3x from '../assets/scroll-mob@3x.avif';

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation();

  // Show button when page is scrolled up to given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Don't show on homepage
  if (location.pathname === '/') return null;

  return (
    <button
      onClick={scrollToTop}
      className="scroll-to-top"
      style={{ 
        opacity: isVisible ? 1 : 0,
        pointerEvents: isVisible ? 'auto' : 'none'
      }}
      aria-label="Scroll to top"
    >
      <picture>
        <source
          media="(min-width: 768px)"
          srcSet={`${scroll3x} 3x, ${scroll2x} 2x, ${scrollImg}`}
        />
        <source
          srcSet={`${scrollMob3x} 3x, ${scrollMob2x} 2x, ${scrollMob}`}
        />
        <img src={scrollImg} alt="Scroll to top" />
      </picture>
    </button>
  );
};

export default ScrollToTop;
