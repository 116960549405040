import React from "react";
import { createRoot } from "react-dom/client";
import Main from "./Main";
import "./css/index.css";
import { CookiesProvider } from "react-cookie";

const root = createRoot(document.getElementById("root"));
root.render(
  <CookiesProvider>
    <Main />
  </CookiesProvider>
);
