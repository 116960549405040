import React from "react";
import { createBrowserRouter, RouterProvider, ScrollRestoration, Outlet } from "react-router-dom";
import Intro from "./Intro";
import We from "./We";
import Activations from "./Activations";
import Digital from "./Digital";
import Content from "./Content";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import "./css/ScrollToTop.css";
import Project from "./Project";

const Layout = () => (
  <section className="content">
    <div className="content-container">
      <Header />
      <main>
        <ScrollRestoration />
        <Outlet />
      </main>
      <Footer />
      <ScrollToTop />
    </div>
  </section>
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Intro />
  },
  {
    path: "/we",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <We />
      }
    ]
  },
  {
    path: "/digital",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Digital />
      },
      {
        path: "*",
        element: <Project type="digital" />
      }
    ]
  },
  {
    path: "/activations",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Activations />
      },
      {
        path: "*",
        element: <Project type="activations" />
      }
    ]
  },
  {
    path: "/content",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Content />
      },
      {
        path: "*",
        element: <Project type="content" />
      }
    ]
  }
]);

export default function Main() {
  return <RouterProvider router={router} />;
}
