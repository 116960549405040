const digital = [
    {
        thumbnailUrl: "/digital/cedar-summit/thumb.png",
        subsection:"Microsites",
        path: "/digital/cedar-summit/",
        client: "Cedar",
        projectName: "Summit",
        type: "digital",
        legacyProject: false,
        description:
          "We collaborated with Cedar Medical to create a B2B digital event where virtual attendees could experience a 3D immersive environment similar to the venue where the brand usually hosts their in-person conference. Guests navigated in first person throughout the space and not only watched 25 live speaker streamed presentations, but also joined breakout sessions, engaged in trivia gameplay, live chat, signed up for virtual activities, and socialized live over cocktails. This event saw high guest engagement with 62% participating in interactive activities.",
        gallery: [
          {
            url: "https://player.vimeo.com/video/656430672?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
            type: "vimeo",
            alt: "",
            sizeClass: "full",
          }
        ],
      },
      {
        thumbnailUrl: "/digital/universal-music-carries-winter-wonderland/thumb.png",
        subsection:"Microsites",
        path: "/digital/universal-music-carries-winter-wonderland/",
        client: "Universal Music",
        projectName: "Carrie's Winter Wonderland",
        type: "digital",
        legacyProject: true,
        description:
          "We were tapped by Universal Music and Carrie Underwood to create a Winter Wonderland digital event in support of her My Gift holiday album launch and featuring unique content and experiences for her community. Carrie’s commitment to her fans and desire to connect with them really demonstrates the power of what virtual events deliver. We brought her My Gift album cover to life in this digital space and filled the event with exclusive messages, AR experiences, gamified giveaways and curated content from Carrie herself. We also developed a strategic traffic driving social campaign which Carrie launched on her channels, getting over 200K+ views and driving thousands to the event in its first few days.",
        gallery: [
          {
            url: "https://player.vimeo.com/video/612353616?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
            type: "vimeo",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/digital/universal-music-carries-winter-wonderland/carries-winter-wonderland_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/universal-music-carries-winter-wonderland/carries-winter-wonderland_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/universal-music-carries-winter-wonderland/carries-winter-wonderland_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/universal-music-carries-winter-wonderland/carries-winter-wonderland_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/universal-music-carries-winter-wonderland/carries-winter-wonderland_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/universal-music-carries-winter-wonderland/carries-winter-wonderland_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/digital/universal-music-carries-winter-wonderland/carries-winter-wonderland_8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/universal-music-carries-winter-wonderland/carries-winter-wonderland_9.gif",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/universal-music-carries-winter-wonderland/carries-winter-wonderland_10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/universal-music-carries-winter-wonderland/carries-winter-wonderland_11.gif",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/universal-music-carries-winter-wonderland/carries-winter-wonderland_12.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/digital/afropunk-planet-afropunk-festival/thumb.png",
        subsection:"Microsites",
        path: "/digital/afropunk-planet-afropunk-festival/",
        client: "AFROPUNK",
        projectName: "Planet AFROPUNK Festival",
        type: "digital",
        legacyProject: true,
        description:
          "Over the past few years the PLANET AFROPUNK Festival grew from a grassroots community movement to a powerful worldwide phenomenon. They recently partnered with Target in 2020 to take the event virtual and expand its global reach exponentially. As their partner we conceptualized and designed the AFROPUNK Ideaville festival destination where we live streamed 50+ musical artists, cultural icons, and thought leaders from all over the world. This digital experience was a first for both Target and AFROPUNK with over 15,000 attendees across four continents, all with rich engagement including surveys, live chat panel discussions with global influencers, accessing exclusive playlists, participating in self care sessions, and receiving discounts to purchase books from renowned Black authors.",
        gallery: [
          {
            url: "https://player.vimeo.com/video/612334989?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
            type: "vimeo",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/digital/afropunk-planet-afropunk-festival/afropunk_festival_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/afropunk-planet-afropunk-festival/afropunk_festival_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/afropunk-planet-afropunk-festival/afropunk_festival_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/afropunk-planet-afropunk-festival/afropunk_festival_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/afropunk-planet-afropunk-festival/afropunk_festival_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/afropunk-planet-afropunk-festival/afropunk_festival_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/digital/afropunk-planet-afropunk-festival/afropunk_festival_8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/afropunk-planet-afropunk-festival/afropunk_festival_9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/afropunk-planet-afropunk-festival/afropunk_festival_10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/afropunk-planet-afropunk-festival/afropunk_festival_11.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/afropunk-planet-afropunk-festival/afropunk_festival_12.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/digital/mcdonalds-otp-conference/thumb.png",
        subsection:"Microsites",
        path: "/digital/mcdonalds-otp-conference/",
        client: "Mcdonald's",
        projectName: "OTP Conference",
        type: "digital",
        legacyProject: false,
        description:
          `<p>We developed an ongoing educational virtual series to dynamically engage McDonald’s professional technician community. </p>

<p>The format included educational content streaming as well as interactive elements like surveys, trivia games, Q&A, and networking to drive participation. 90% of the users engaged in the interactive features.</p>`,
gallery: [
    {
        url: "https://player.vimeo.com/video/654738108?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full"
  },
          {
            url: "/digital/mcdonalds-otp-conference/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/mcdonalds-otp-conference/2.png",
            alt: "",
            sizeClass: "half",
          },
        ]
      },
      {
        thumbnailUrl: "/digital/monat-wellness-experience/thumb.png",
        subsection:"Microsites",
        path: "/digital/monat-wellness-experience/",
        client: "Monat",
        projectName: "Wellness Experience",
        type: "digital",
        legacyProject: true,
        description:
          "We partnered with MONAT Beauty to celebrate their brand culture and launch their new Wellness line to their 40,000+ worldwide selling partners and VIPs. The brand understood the need to meet their community in the virtual space and the importance of delivering interactive and innovative digital experiences that would surprise their audience with engagement beyond just passively watching content. To deliver on their ask, we pushed digital experiential boundaries and developed a 100% custom and unique digital event including content creation, AR product animations, a digital game arcade and virtual photo booth.",
        gallery: [
          {
            url: "https://player.vimeo.com/video/612789560?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
            type: "vimeo",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/digital/monat-wellness-experience/monat_wellness-experience_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/monat-wellness-experience/monat_wellness-experience_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/monat-wellness-experience/monat_wellness-experience_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/monat-wellness-experience/monat_wellness-experience_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/monat-wellness-experience/monat_wellness-experience_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/monat-wellness-experience/monat_wellness-experience_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/digital/monat-wellness-experience/monat_wellness-experience_8.jpg",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/monat-wellness-experience/monat_wellness-experience_9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/monat-wellness-experience/monat_wellness-experience_10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/monat-wellness-experience/monat_wellness-experience_11.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/monat-wellness-experience/monat_wellness-experience_12.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/digital/dexcom-game-day/thumb.png",
        subsection:"Microsites",
        path: "/digital/dexcom-game-day/",
        client: "Dexcom",
        projectName: "Game Day",
        type: "digital",
        legacyProject: true,
        description:
          "For the 2021 Super Bowl, we were challenged by diabetes technology leader, Dexcom, to develop a way to engage audiences around their first ever commercial and celebrity talent announcement, Nick Jonas. They wanted diabetics and football fans to be able to digitally connect with him. We leveraged innovative AR video technology to enable people to “virtually have Nick over for the Super Bowl” and create highly shareable animated content. Users were given the option between the two videos of Nick and were able to record themselves next to him in their homes. Thousands of Superbowl watchers participated in this novel interactive experience. Nick himself found value in the experience and was quoted in Adweek saying, “Anytime I can find a way to connect with my fans and beyond in this virtual world...I am excited by it.",
        gallery: [
          {
            url: "https://player.vimeo.com/video/612353660?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
            type: "vimeo",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/digital/dexcom-game-day/dexcom_game-day_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/dexcom-game-day/dexcom_game-day_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/dexcom-game-day/dexcom_game-day_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/dexcom-game-day/dexcom_game-day_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/dexcom-game-day/dexcom_game-day_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/dexcom-game-day/dexcom_game-day_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/digital/dexcom-game-day/dexcom_game-day_8.png",
            alt: "",
            sizeClass: "onethird",
          },
        ],
      },
      {
        thumbnailUrl: "/digital/kohls-kohls-home-launch/thumb.png",
        subsection:"Microsites",
        path: "/digital/kohls-kohls-home-launch/",
        client: "Kohl's",
        projectName: "Kohl's Home Launch",
        type: "digital",
        legacyProject: false,
        description:
          `<p>Celebrating Kohl's relaunch of "Home for Everyone," TH was tasked with developing a comprehensive 360 integrated campaign aimed at inspiring consumers to decorate their homes with Kohl's products. This multi-channel activation featured a digital experience allowing hundreds of thousands of people to browse the collection, get inspired by influencer curations and create vision boards of their favorite stylish products at affordable prices and with the chance to win them all.</p>
          <p>Our campaign also featured a dynamic pop-up event showcasing Kohl's home decor collection. This one day experience drove earned media, was the backdrop for 60 influencers to organic create content and live streams for social impressions, and build brand awareness with over 1,000 new consumers data collected.</p>`,
        gallery: [
          {
            url: "https://player.vimeo.com/video/933884091?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
            type: "vimeo",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/digital/kohls-kohls-home-launch/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/kohls-kohls-home-launch/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/kohls-kohls-home-launch/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/kohls-kohls-home-launch/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/kohls-kohls-home-launch/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/kohls-kohls-home-launch/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/digital/kohls-kohls-home-launch/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/kohls-kohls-home-launch/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/kohls-kohls-home-launch/9.png",
            alt: "",
            sizeClass: "half",
          }

        ],
      },
      {
        thumbnailUrl: "/digital/kohls-build-a-list/thumb.png",
        subsection:"Microsites",
        path: "/digital/kohls-build-a-list/",
        client: "Kohl's",
        projectName: "Build-A-List",
        type: "digital",
        legacyProject: false,
        description:
          "We partnered with Kohl’s on a 360-online to offline campaign featuring a custom digital experience and an in person pop-up. Kohl’s Build-A-List microsite enabled consumers to curate and personalize their Holiday Wishlist to share with friends, family and on social media by tagging @Kohls and #BuildAListSweepstakes for a chance to win $250 Kohl’s Cash.<br/><br/>This 360 digital activation includes organic social, paid social, influencer marketing, email, SMS, ecommerce and in-person events for maximum reach and results.",
        gallery: [
          {
            url: "/digital/kohls-build-a-list/1.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/digital/kohls-build-a-list/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/kohls-build-a-list/3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/kohls-build-a-list/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/kohls-build-a-list/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/kohls-build-a-list/6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/kohls-build-a-list/7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/digital/kohls-build-a-list/8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/kohls-build-a-list/9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/kohls-build-a-list/10.png",
            alt: "",
            sizeClass: "half",
          },
        ],
      },
      {
        thumbnailUrl: "/digital/american-eagle-holiday-wishlist/thumb.png",
        subsection:"Microsites",
        path: "/digital/american-eagle-holiday-wishlist/",
        client: "American Eagle",
        projectName: "Holiday Wishlist",
        type: "digital",
        legacyProject: false,
        description:
          `<p>TH partnered with American Eagle to create an experience where fans of the brand could select items they wanted to purchase and create their very own personal Holiday Wishlist that they could then share with their community. </p>

<p>The activation included a sweepstakes contest where participants could win their wishlist.</p>`,
gallery: [
          {
            url: "/digital/american-eagle-holiday-wishlist/hero.gif",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/digital/american-eagle-holiday-wishlist/1.gif",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/american-eagle-holiday-wishlist/2.gif",
            alt: "",
            sizeClass: "half",
          },
          
],
      },
      {
        thumbnailUrl: "/digital/homegoods-house-of-homegoods/thumb.png",
        subsection:"Microsites",
        path: "/digital/homegoods-house-of-homegoods/",
        client: "HomeGoods",
        projectName: "House of HomeGoods",
        type: "digital",
        legacyProject: false,
        description:
          "TH partnered with Edelman to bring to life the versatility of the HomeGoods brand through House of HomeGoods—an activation that highlighted immersive interiors and encouraged organic content creation. The activation featured a bookable getaway that offered consumers a chance to discover something new, as each visit was transformed into a new theme. For each theme, TH curated HomeGoods decor, wall treatments and furnishings inspiration showcasing interior design partners that would be a good fit for each genre. Every flip was distinct and different. The themes were completed with photo-worthy environments and unique activities that generated rich and compelling content for HomeGoods.",
        gallery: [
          {
            url: "/digital/homegoods-house-of-homegoods/1.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/digital/homegoods-house-of-homegoods/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/homegoods-house-of-homegoods/3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/homegoods-house-of-homegoods/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/homegoods-house-of-homegoods/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/homegoods-house-of-homegoods/6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/homegoods-house-of-homegoods/7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/digital/homegoods-house-of-homegoods/8.png",
            alt: "",
            sizeClass: "onethird",
          }
        ],
      },
      {
        thumbnailUrl: "/digital/jetblue-bermuda/thumb.png",
        subsection:"Microsites",
        path: "/digital/jetblue-bermuda/",
        client: "Jetblue",
        projectName: "Bermuda",
        type: "digital",
        legacyProject: false,
        description:
        `<p>TH partnered with JetBlue to create a pop-up in a high traffic area of NYC launching their newest 90 minute flights to Bermuda.</p>
          <p>This pop-up included an 8ft timer glass that rotated every 90 minutes and live demonstrating the incredibly short flight time. We leveraged a combination of several digital/tech components to bring this activation to life including a microsite to facilitate a sweepstakes sign up where guests could win a ticket, embedded mobile trivia experiences to learn more about the destination, and an Integrated immersive mixed reality experience transporting people to the pink sands of Bermuda.</p>`,
          gallery: [
            {
                url: "https://player.vimeo.com/video/967057560?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
                type: "vimeo",
                alt: "",
                sizeClass: "full"
          },
          {
            url: "/digital/jetblue-bermuda/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/jetblue-bermuda/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/jetblue-bermuda/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/jetblue-bermuda/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/jetblue-bermuda/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/jetblue-bermuda/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/digital/jetblue-bermuda/7.gif",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/jetblue-bermuda/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/jetblue-bermuda/9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/jetblue-bermuda/10.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/jetblue-bermuda/11.png",
            alt: "",
            sizeClass: "twothird",
          }
        ],
      },
      {
        thumbnailUrl: "/digital/valentino-beauty-virtual-press-and-influencer-event/thumb.png",
        subsection:"Microsites",
        path: "/digital/valentino-beauty-virtual-press-and-influencer-event/",
        client: "Valentino Beauty",
        projectName: "Virtual Press & Influencer Event",
        type: "digital",
        legacyProject: true,
        description:
          "We partnered with Valentino Beauty to launch the brand with a virtual press event. We filmed in real life at Root Studios in Brooklyn, and streamed live to over 200 press and influencers. We found that 52% of people are interested in virtual events and this approach contributed to a successful 71% RSVP attendance and over 28 million social impressions. Over half of the press and media attendees participated in the live chat and shared feedback on the artistry and products, many of them having the opportunity to trial the products in advance with their seeding kits. The event included a consumer extension on YouTube with integrated ecommerce and got the brand 61,000 impressions on the newly launched YouTube channel.",
        gallery: [
          {
            url: "https://player.vimeo.com/video/655534073?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
            type: "vimeo",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/digital/valentino-beauty-virtual-press-and-influencer-event/valentino_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/valentino-beauty-virtual-press-and-influencer-event/valentino_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/valentino-beauty-virtual-press-and-influencer-event/valentino_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/valentino-beauty-virtual-press-and-influencer-event/valentino_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/valentino-beauty-virtual-press-and-influencer-event/valentino_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/valentino-beauty-virtual-press-and-influencer-event/valentino_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/digital/valentino-beauty-virtual-press-and-influencer-event/valentino_8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/valentino-beauty-virtual-press-and-influencer-event/valentino_9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/valentino-beauty-virtual-press-and-influencer-event/valentino_10.png",
            alt: "",
            sizeClass: "half",
          },
        ],
      },
      {
        thumbnailUrl: "/digital/jetblue-bermuda/thumb-alt.png",
        subsection:"AR/VR/Holograms",
        path: "/digital/jetblue-bermuda/",
        client: "Jetblue",
        projectName: "Bermuda",
        type: "digital",
        legacyProject: false,
        description:
        `<p>TH partnered with JetBlue to create a pop-up in a high traffic area of NYC launching their newest 90 minute flights to Bermuda.</p>
          <p>This pop-up included an 8ft timer glass that rotated every 90 minutes and live demonstrating the incredibly short flight time. We leveraged a combination of several digital/tech components to bring this activation to life including a microsite to facilitate a sweepstakes sign up where guests could win a ticket, embedded mobile trivia experiences to learn more about the destination, and an Integrated immersive mixed reality experience transporting people to the pink sands of Bermuda.</p>`,
          gallery: [
            {
                url: "https://player.vimeo.com/video/967057560?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
                type: "vimeo",
                alt: "",
                sizeClass: "full"
          },
          {
            url: "/digital/jetblue-bermuda/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/jetblue-bermuda/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/jetblue-bermuda/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/jetblue-bermuda/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/jetblue-bermuda/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/jetblue-bermuda/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/digital/jetblue-bermuda/7.gif",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/jetblue-bermuda/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/jetblue-bermuda/9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/jetblue-bermuda/10.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/jetblue-bermuda/11.png",
            alt: "",
            sizeClass: "twothird",
          }
        ],
      },
      {
        thumbnailUrl: "/digital/brickell-holiday-activation/thumb.png",
        subsection:"AR/VR/Holograms",
        path: "/digital/brickell-holiday-activation/",
        client: "Brickell",
        projectName: "Holiday Activation",
        type: "digital",
        legacyProject: false,
        description:
          `<p>TH Experiential partners with Swire Properties to produce their annual holiday installation at Brickell City Centre in Miami, FL.</p>

<p>Leveraging Swire’s holiday creative direction, we design and execute engaging holiday installations that entertain and engage local residents and families. Each year, we update the format to include tech innovation content creation moments, creative activities, and immersive photo moments. </p>`,
gallery: [
    {
        url: "/digital/brickell-holiday-activation/hero.png",
        alt: "",
        sizeClass: "full",
    },
    {
        url: "/digital/brickell-holiday-activation/1.png",
        alt: "",
        sizeClass: "half",
    },
    {
        url: "/digital/brickell-holiday-activation/2.png",
        alt: "",
        sizeClass: "half",
    },
    {
        url: "/digital/brickell-holiday-activation/3.gif",
        alt: "",
        sizeClass: "onethird",
    },
    {
        url: "/digital/brickell-holiday-activation/4.png",
        alt: "",
        sizeClass: "onethird",
    },
    {
        url: "/digital/brickell-holiday-activation/5.png",
        alt: "",
        sizeClass: "onethird",
    },
    {
        url: "/digital/brickell-holiday-activation/6.png",
        alt: "",
        sizeClass: "twothird",
    },
    {
        url: "/digital/brickell-holiday-activation/7.gif",
        alt: "",
        sizeClass: "onethird",
    },
    
]
      },
      {
        thumbnailUrl: "/digital/chase-wanderlust-vr-experience/thumb.png",
        subsection:"AR/VR/Holograms",
        path: "/digital/chase-wanderlust-vr-experience/",
        client: "Chase",
        projectName: "Wanderlust Vr Experience",
        type: "digital",
        legacyProject: false,
        description:
          `<p>Chase wanted to transport their top executives on a virtual experience, where they could temporarily leave behind their work from home lives and travel for business again. </p>

<p>We developed a virtual reality journey where they boarded a plane, traveled and landed at an exotic destination to enjoy a paradise excursion.</p>`,
gallery: [
    {
        url: "/digital/chase-wanderlust-vr-experience/hero.gif",
        alt: "",
        sizeClass: "full",
    }
]
      },
      {
        thumbnailUrl: "/digital/cetaphil-malibu-skinlabs/thumb.png",
        subsection:"AR/VR/Holograms",
        path: "/digital/cetaphil-malibu-skinlabs/",
        client: "Cetaphil",
        projectName: "Malibu Skinlabs",
        type: "digital",
        legacyProject: false,
        description:
          `<p>TH transformed a beachfront villa in Malibu into a two-day "Skinlabs" global influencer event, hosting over 120 global creators and media to explore the science behind Cetaphil's products through engaging, multi-sensory digital. From testing hydration levels in the Skin Lab to diving deep into skincare with VR in the Tech Lab, each activity offered a unique opportunity for influencers to learn about Cetaphil’s formulas and personalize their skincare routine using AI and AR tech.&nbsp;</p>
          <p>TH designed and developed the entire experience, launching the rebrand while maximizing opportunities for influencers to create and share content across their platforms.</p>`,
          gallery: [
            {
                url: "https://player.vimeo.com/video/1048682936?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
                type: "vimeo",
                alt: "",
                sizeClass: "full"
          },
            {
                url: "/digital/cetaphil-malibu-skinlabs/1.png",
                alt: "",
                sizeClass: "half",
            },
            {
                url: "/digital/cetaphil-malibu-skinlabs/2.png",
                alt: "",
                sizeClass: "half",
            },
            {
                url: "/digital/cetaphil-malibu-skinlabs/3.gif",
                alt: "",
                sizeClass: "onethird",
            },
            {
                url: "/digital/cetaphil-malibu-skinlabs/4.png",
                alt: "",
                sizeClass: "onethird",
            },
            {
                url: "/digital/cetaphil-malibu-skinlabs/5.png",
                alt: "",
                sizeClass: "onethird",
            },
            {
                url: "/digital/cetaphil-malibu-skinlabs/6.png",
                alt: "",
                sizeClass: "twothird",
            },
            {
                url: "/digital/cetaphil-malibu-skinlabs/7.gif",
                alt: "",
                sizeClass: "onethird",
            },
            {
                url: "/digital/cetaphil-malibu-skinlabs/8.gif",
                alt: "",
                sizeClass: "half",
            },
            {
                url: "/digital/cetaphil-malibu-skinlabs/9.png",
                alt: "",
                sizeClass: "half",
            },
            {
                url: "/digital/cetaphil-malibu-skinlabs/10.png",
                alt: "",
                sizeClass: "onethird",
            },
            {
                url: "/digital/cetaphil-malibu-skinlabs/11.png",
                alt: "",
                sizeClass: "twothird",
            }
          
        ],
      },
      {
        thumbnailUrl: "/digital/ibm-hidden-figures-at-ces/thumb.png",
        subsection:"AR/VR/Holograms",
        path: "/digital/ibm-hidden-figures-at-ces/",
        client: "IBM",
        projectName: "Hidden Figures @ CES",
        type: "digital",
        legacyProject: false,
        description:
          `<p>TH created a unique and dramatic moment for IBM at CES at Las Vegas to celebrate the Hidden Figures movie launch.</p>

<p>This custom immersive experience ran for 4 days and showcased IBM's campaign through a mix of technology innovation including video content, augmented reality, holographic projections and directional sound.</p>`,
        gallery: [
          {
            url: "/digital/ibm-hidden-figures-at-ces/hero.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/digital/ibm-hidden-figures-at-ces/1.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/ibm-hidden-figures-at-ces/2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/ibm-hidden-figures-at-ces/3.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/ibm-hidden-figures-at-ces/4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/ibm-hidden-figures-at-ces/5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/ibm-hidden-figures-at-ces/6.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/digital/ibm-hidden-figures-at-ces/7.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/ibm-hidden-figures-at-ces/8.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/ibm-hidden-figures-at-ces/9.png",
            alt: "",
            sizeClass: "half",
          }
        ],
      },
      {
        thumbnailUrl: "/digital/monat-wellness-experience/thumb-alt.png",
        subsection:"AR/VR/Holograms",
        path: "/digital/monat-wellness-experience/",
        client: "Monat",
        projectName: "Wellness Experience",
        type: "digital",
        legacyProject: true,
        description:
          "We partnered with MONAT Beauty to celebrate their brand culture and launch their new Wellness line to their 40,000+ worldwide selling partners and VIPs. The brand understood the need to meet their community in the virtual space and the importance of delivering interactive and innovative digital experiences that would surprise their audience with engagement beyond just passively watching content. To deliver on their ask, we pushed digital experiential boundaries and developed a 100% custom and unique digital event including content creation, AR product animations, a digital game arcade and virtual photo booth.",
        gallery: [
          {
            url: "https://player.vimeo.com/video/612789560?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
            type: "vimeo",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/digital/monat-wellness-experience/monat_wellness-experience_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/monat-wellness-experience/monat_wellness-experience_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/monat-wellness-experience/monat_wellness-experience_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/monat-wellness-experience/monat_wellness-experience_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/monat-wellness-experience/monat_wellness-experience_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/monat-wellness-experience/monat_wellness-experience_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/digital/monat-wellness-experience/monat_wellness-experience_8.jpg",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/monat-wellness-experience/monat_wellness-experience_9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/monat-wellness-experience/monat_wellness-experience_10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/monat-wellness-experience/monat_wellness-experience_11.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/monat-wellness-experience/monat_wellness-experience_12.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/digital/olly-jet-blue-upgrade-your-sleep/thumb.png",
        subsection:"Games",
        path: "/digital/olly-jet-blue-upgrade-your-sleep/",
        client: "Olly x Jet Blue",
        projectName: "Upgrade Your Sleep",
        type: "digital",
        legacyProject: true,
        description:
          "The OLLY X JetBlue Upgrade Your Sleep pop-up attracted travelers at John F. Kennedy International Airport to an sleep themed immersive event. Guests explored OLLY branded air travel vignettes where they could learn about how to improve their sleep, discover OLLY supplements and create content. These experiences offered safe, mobile-first engagement with QR codes that launched Spin the Wheel gamification for prize winning or motion triggered AR photo moments. The activation drove an estimated 40,000 visual impressions over the course of 2 days.",
        gallery: [
          {
            url: "/digital/olly-jet-blue-upgrade-your-sleep/ollyjb_1.png",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/digital/olly-jet-blue-upgrade-your-sleep/ollyjb_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/olly-jet-blue-upgrade-your-sleep/ollyjb_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/olly-jet-blue-upgrade-your-sleep/ollyjb_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/olly-jet-blue-upgrade-your-sleep/ollyjb_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/olly-jet-blue-upgrade-your-sleep/ollyjb_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/olly-jet-blue-upgrade-your-sleep/ollyjb_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/digital/olly-jet-blue-upgrade-your-sleep/ollyjb_8.gif",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/olly-jet-blue-upgrade-your-sleep/ollyjb_9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/olly-jet-blue-upgrade-your-sleep/ollyjb_10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/olly-jet-blue-upgrade-your-sleep/ollyjb_11.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/olly-jet-blue-upgrade-your-sleep/ollyjb_12.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/digital/farmacy-virtual-product-launch/thumb.png",
        subsection:"Games",
        path: "/digital/farmacy-virtual-product-launch/",
        client: "Farmacy",
        projectName: "Virtual Product Launch",
        type: "digital",
        legacyProject: true,
        description:
          `<p>We designed a custom site for Farmacy’s Spring Product Launch where we took press and influencers on a journey where they not only learned about the new products, but also engaged in philanthropic and personalization activities. </p>

<p>Guest networked with each other and brand representatives in the live chat, played a custom developed game simulating the brand’s product creation lab where guests assembled the product ingredients, and personalized launch products with custom label designs to send to friends. </p>

<p>Guests gameplay points triggered donations to the charity Feeding America.</p>`,
        gallery: [
          {
            url: "https://player.vimeo.com/video/1054281326?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
            type: "vimeo",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/digital/farmacy-virtual-product-launch/farmacy_launch_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/farmacy-virtual-product-launch/farmacy_launch_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/farmacy-virtual-product-launch/farmacy_launch_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/farmacy-virtual-product-launch/farmacy_launch_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/farmacy-virtual-product-launch/farmacy_launch_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/farmacy-virtual-product-launch/farmacy_launch_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/digital/farmacy-virtual-product-launch/farmacy_launch_8.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/farmacy-virtual-product-launch/farmacy_launch_9.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/farmacy-virtual-product-launch/farmacy_launch_10.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/farmacy-virtual-product-launch/farmacy_launch_11.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/farmacy-virtual-product-launch/farmacy_launch_12.png",
            alt: "",
            sizeClass: "twothird",
          },
        ],
      },
      {
        thumbnailUrl: "/digital/bayer-escape-from-kidney-island/thumb.png",
        subsection:"Games",
        path: "/digital/bayer-escape-from-kidney-island/",
        client: "Bayer",
        projectName: "Escape From Kidney Island",
        type: "digital",
        legacyProject: false,
        description:
          "To engage Bayer Pharmaceuticals conference attendees around the launch of their groundbreaking new kidney drug, we created an interactive and educational game that generated awareness in both a fun and innovative way. The “Escape Kidney Island” game allowed players to select an avatar and earn points by answering a series of questions developed to educate them on both the dangers of kidney disease and the life saving possibilities of this new drug. The gamified experience was well received and leveraged to create an internal tournament and then rolled out to the global Bayer community of 100,000 employees.",
        gallery: [
          {
            url: "https://player.vimeo.com/video/612353705?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
            type: "vimeo",
            alt: "",
            sizeClass: "full",
          },
          {
            url: "/digital/bayer-escape-from-kidney-island/bayer_kidney-island_2.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/bayer-escape-from-kidney-island/bayer_kidney-island_3.png",
            alt: "",
            sizeClass: "half",
          },
          {
            url: "/digital/bayer-escape-from-kidney-island/bayer_kidney-island_4.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/bayer-escape-from-kidney-island/bayer_kidney-island_5.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/bayer-escape-from-kidney-island/bayer_kidney-island_6.png",
            alt: "",
            sizeClass: "onethird",
          },
          {
            url: "/digital/bayer-escape-from-kidney-island/bayer_kidney-island_7.png",
            alt: "",
            sizeClass: "twothird",
          },
          {
            url: "/digital/bayer-escape-from-kidney-island/bayer_kidney-island_8.png",
            alt: "",
            sizeClass: "onethird",
          },
        ],
      },
      {
        thumbnailUrl: "/digital/primark-new-store-opening/thumb.png",
        subsection:"Games",
        path: "/digital/primark-new-store-opening/",
        client: "Primark",
        projectName: "NEW STORE OPENING",
        type: "digital",
        legacyProject: false,
        description:
          `<p>To celebrate Primark’s New Store Openings across New York City, we partnered to create an innovative and exciting branded campaign that was targeted to each micro-market. Each activation was customized to the specific store and designed to be modular and scalable, while engaging customers to support the Primark objective of connecting the brand to local communities. </p>

<p>Brand Ambassadors invited shoppers into the store to participate in various touch points that further promoted Primark’s brand messaging and shopping experience. From live DJ entertainment to gamification and photo moments, we gave shoppers a memorable in-store experience keeping Primark top of mind for all consumer’s future shopping needs and reinforcing storytelling around great style for prices.</p>`,
gallery: [
  {
    url: "/digital/primark-new-store-opening/hero.png",
    alt: "",
    sizeClass: "full",
  },
  {
    url: "/digital/primark-new-store-opening/1.png",
    alt: "",
    sizeClass: "half",
  },
  {
    url: "/digital/primark-new-store-opening/2.gif",
    alt: "",
    sizeClass: "half",
  },
  {
    url: "/digital/primark-new-store-opening/3.png",
    alt: "",
    sizeClass: "onethird",
  },
  {
    url: "/digital/primark-new-store-opening/4.gif",
    alt: "",
    sizeClass: "onethird",
  },
  {
    url: "/digital/primark-new-store-opening/5.png",
    alt: "",
    sizeClass: "onethird",
  },
  {
    url: "/digital/primark-new-store-opening/6.png",
    alt: "",
    sizeClass: "twothird",
  },
  {
    url: "/digital/primark-new-store-opening/7.gif",
    alt: "",
    sizeClass: "onethird",
  }
]
      },
      {
        thumbnailUrl: "/digital/waterwipes-hallowclean/thumb.png",
        subsection:"Games",
        path: "/digital/waterwipes-hallowclean/",
        client: "Waterwipes",
        projectName: "Hallowclean",
        type: "digital",
        legacyProject: false,
        description:
          `<p>To drive awareness for the Waterwipes brand to be recognized as more than just a baby product, TH custom developed the ‘Hallowclean Machine’ game that allowed parents and children to measure their Halloween candy “mess”. After going through the experience, families received a travel pack of wipes to stay “Hallowclean” on the go. Musician and actress Kelly Rowland made an appearance with her son, Noah.</p>

<p>Additionally, on Halloween night, TH placed mini Hallowclean stations in New York, Chicago, Denver, Dallas, and Los Angeles, allowing parents to have a scary-clean solution for their spook-tacularly sticky trick-or-treaters. </p>`,
gallery: [
    {
        url: "/digital/waterwipes-hallowclean/hero.png",
        alt: "",
        sizeClass: "full",
    },
    {
        url: "/digital/waterwipes-hallowclean/1.png",
        alt: "",
        sizeClass: "half",
    },
    {
        url: "/digital/waterwipes-hallowclean/2.png",
        alt: "",
        sizeClass: "half",
    },
    {
        url: "/digital/waterwipes-hallowclean/3.png",
        alt: "",
        sizeClass: "onethird",
    },
    {
        url: "/digital/waterwipes-hallowclean/4.png",
        alt: "",
        sizeClass: "onethird",
    },
    {
        url: "/digital/waterwipes-hallowclean/5.png",
        alt: "",
        sizeClass: "onethird",
    },
    {
        url: "/digital/waterwipes-hallowclean/6.png",
        alt: "",
        sizeClass: "twothird",
    },
    {
        url: "/digital/waterwipes-hallowclean/7.png",
        alt: "",
        sizeClass: "onethird",
    }
]
      },
      {
        thumbnailUrl: "/digital/ck-for-everyone/thumb.png",
        subsection:"Games",
        path: "/digital/ck-for-everyone/",
        client: "CK",
        projectName: "For Everyone",
        type: "digital",
        legacyProject: false,
        description:
          `<p>To launch their newest eco-fragrance, CK partnered with us to develop a custom “brick breaker” game. Players were encouraged to collect all of the sustainability icons. As each icon was collected, a pop up appeared with educational messaging. Once all icons were collected, they were then arranged in the shape of the bottle to reveal the product identity.</p>
          <p>The game ended with a CTA to buy and discover ckEveryone and players were directed to the Calvin Klein global site to locate the nearest store for purchase.</p>`,
          gallery:[
            {
              url: "https://player.vimeo.com/video/1057701842?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
              type: "vimeo",
              alt: "",
              sizeClass: "full",
            },
            {
                url: "/digital/ck-for-everyone/1.png",
                alt: "",
                sizeClass: "half",
            },
            {
                url: "/digital/ck-for-everyone/2.png",
                alt: "",
                sizeClass: "half",
            }
          ]
      }
      
]

export default digital;