import LinkGrid from "./components/LinkGrid";
import activationsData from "./components/activations-data";

function Activations() {
  const subsections = [
    'Live Events',
    'Pop Ups',
    'Mobile Tours',
    'Press and Influencer Events',
    'Cultural Events',
    'Tradeshows and Conferences'
  ];

  return <>
    {subsections.map(subsection => {
      const subsectionData = activationsData.filter(item => item.subsection === subsection);
      if (subsectionData.length > 0) {
        return (
          <div className="subsection" key={subsection}>
            <h2 className="subsection-heading">{subsection}</h2>
            <LinkGrid gridData={subsectionData} />
          </div>
        );
      }
      return null;
    })}
  </>;
}

export default Activations;
