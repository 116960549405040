const content = [
  {
    thumbnailUrl: "/content/opi-40th-anniversary/thumb.png",
    subsection:"Live Streaming",
    path: "/content/opi-40th-anniversary/",
    client: "OPI",
    projectName: "40th anniversary",
    type: "content",
    legacyProject: false,
    description:
      `To celebrate three major moments for the OPI brand including their: 40th Anniversary, the release of their iconic 40 colors, and participation in Nail Tech Day we created a virtual media event to connect attendees with both Suzi Weiss-Fischmann, OPI CEO and celebrity brand ambassador Kerry Washington. Guests were engaged through brand trivia, reveal of the new summer colors, nail art trends, voting on their favorite looks, and live Q+A with both Suzy and Kerri. Through these exclusive moments, press and media experienced and interacted with the OPI brand in a unique, disruptive way. This highly engaging format led to a 94% retention rate and 66% of attendees participated in voting and trivia.`,
      gallery: [
        {
            url: "https://player.vimeo.com/video/1050319007?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
            type: "vimeo",
            alt: "",
            sizeClass: "full"
      },
      {
        url: "/content/opi-40th-anniversary/1.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/content/opi-40th-anniversary/2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/content/opi-40th-anniversary/3.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/content/opi-40th-anniversary/4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/content/opi-40th-anniversary/5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/content/opi-40th-anniversary/6.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/content/opi-40th-anniversary/7.png",
        alt: "",
        sizeClass: "onethird",
      },
    ],
  },
  {
    thumbnailUrl: "/content/coursera/thumb.png",
    subsection:"Live Streaming",
    path: "/content/coursera/",
    client: "Coursera",
    projectName: "",
    type: "content",
    legacyProject: false,
    description:
      `<p>TH brought Coursera Connect to the global stage with multi-channel live-streamed keynote presentations, expertly coordinated breakout sessions, and an inspiring awards dinner. </p>

<p>With over 1,100 attendees, Coursera Connect drove impactful discussions, highlighted transformative innovations, and gave exclusive, hands-on access to Coursera’s cutting-edge products, setting a new standard for industry engagement and collaboration.</p>`,
gallery: [
  {
      url: "https://player.vimeo.com/video/1048730084?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
      type: "vimeo",
      alt: "",
      sizeClass: "full"
},
        {
            url: "/content/coursera/1.png",
            alt: "",
            sizeClass: "half",
        },
        {
            url: "/content/coursera/2.gif",
            alt: "",
            sizeClass: "half",
        },
        {
            url: "/content/coursera/3.png",
            alt: "",
            sizeClass: "onethird",
        },
        {
            url: "/content/coursera/4.png",
            alt: "",
            sizeClass: "onethird",
        },
        {
            url: "/content/coursera/5.png",
            alt: "",
            sizeClass: "onethird",
        },
        {
            url: "/content/coursera/6.png",
            alt: "",
            sizeClass: "twothird",
        },
        {
            url: "/content/coursera/7.png",
            alt: "",
            sizeClass: "onethird",
        },
        {
            url: "/content/coursera/8.png",
            alt: "",
            sizeClass: "half",
        },
        {
            url: "/content/coursera/9.png",
            alt: "",
            sizeClass: "half",
        }
    ],
  },
  {
    thumbnailUrl: "/content/chase-questination-unknown/thumb.png",
    subsection:"Live Streaming",
    path: "/content/chase-questination-unknown/",
    client: "Chase United",
    projectName: "Questination Unknown",
    type: "content",
    legacyProject: false,
    description:
      `<p>To get press and influencers equally excited about the Chase United Card and the prospect of traveling again post-covid, we developed a virtual event concept that took them on a journey to experiences in other cities. Pre-event, attendees were shipped the interactive elements needed to participate in the event experiences. </p>

<p>Mario Lopez and the brand host inspired the audience to consider new travel destinations, engaged them with live Q&A, and then invited them to enjoy fun breakout activities from mixology to comedy streamed in from different cities around the country.</p>`,
gallery: [
  {
      url: "https://player.vimeo.com/video/656821095?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
      type: "vimeo",
      alt: "",
      sizeClass: "full"
},
      {
        url: "/content/chase-questination-unknown/1.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/content/chase-questination-unknown/2.png",
        alt: "",
        sizeClass: "half",
      }
    ],
  },
  {
    thumbnailUrl: "/content/anthos-agm2022/thumb.png",
    subsection:"Live Streaming",
    path: "/content/anthos-agm2022/",
    client: "Anthos",
    projectName: "AGM 2022",
    type: "content",
    legacyProject: false,
    description:
      "TH partnered with Anthos to bring their AGM 2022 conference to life online. The event included discussions by Anthos leadership, reviews of the market, trends shaping the future, and Anthos performance and portfolio. Attendees received branded e-vites and had the opportunity to attend via a dedicated microsite including a Q&A, polling, and live replays. We leveraged in-event motion graphics and AV broadcasting to ensure a flawless execution.",
    gallery: [
      {
        url: "https://player.vimeo.com/video/1021943474?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
    ],
  },
  {
    thumbnailUrl: "/content/investopedia-your-money-your-health/thumb.png",
    subsection:"Live Streaming",
    path: "/content/investopedia-your-money-your-health/",
    client: "Investopedia",
    projectName: "Your Money Your Health",
    type: "content",
    legacyProject: false,
    description:
      "Investopedia’s Your Money, Your Health 2021 Virtual Conference invited attendees to explore the undeniable connection between financial wellness and overall health, particularly in the context of the COVID-19 pandemic, and through a series of informative panels led by industry experts.<p>The event included five panels moderated by Investopdia's Editor-in-Chief, Caleb Silver and where over 1,200 attendees were engaged with the live chat, polling, and Q&A interactive features. </p>",
    gallery: [
      {
        url: "https://player.vimeo.com/video/656431174?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      }
    ],
  },
  {
    thumbnailUrl: "/content/lrp-family-feud/thumb.png",
    subsection:"Live Streaming",
    path: "/content/lrp-family-feud/",
    client: "LRP",
    projectName: "Family Feud",
    type: "content",
    legacyProject: false,
    description:
      "We partnered with La Roche-Posay to create a virtual gaming experience on Zoom that sparked conversation around skincare and educated and entertained those who attended. The event format was based on the popular TV game show, Family Feud, and leveraged AV broadcasting and motion graphic innovation to stream into the webinar platform which resembled a microsite experience. The game included three rounds of questions and a “fast money” round hosted by a famous dermatologist to further promote brand messaging and storytelling.",
    gallery: [
      {
        url: "https://player.vimeo.com/video/1021911678?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
    ],
  },
  {
    thumbnailUrl: "/content/carters-holiday-party/thumb.png",
    subsection:"Live Streaming",
    path: "/content/carters-holiday-party/",
    client: "Carter's",
    projectName: "Holiday Party",
    type: "content",
    legacyProject: true,
    description:
      "For the 2020 Holiday season, Carter’s collaborated with us to create a series of digital events empowering parents to refocus on the true meaning of the holidays and reinforce the notion that real joy is being in the company of your family and sharing in nostalgic activities. We leveraged our virtual event platform to bring to life two fully branded influencer led Holiday virtual events: a PJ Party with Leslie Odom and his family and a Baking Party with Whitney Port and her family. Attending consumer families could not only bake-along and read-along, but also enjoyed trivia games, surveys and live Q&A sessions with these celebrity families.",

    gallery: [
      {
        url: "/content/carters-holiday-party/holiday-pj-party_1.gif",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/content/carters-holiday-party/holiday-pj-party_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/content/carters-holiday-party/holiday-pj-party_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/content/carters-holiday-party/holiday-pj-party_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/content/carters-holiday-party/holiday-pj-party_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/content/carters-holiday-party/holiday-pj-party_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/content/carters-holiday-party/holiday-pj-party_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/content/carters-holiday-party/holiday-pj-party_8.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/content/carters-holiday-party/holiday-pj-party_9.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/content/carters-holiday-party/holiday-pj-party_10.png",
        alt: "",
        sizeClass: "half",
      },
    ],
  },
  {
    thumbnailUrl: "/content/topps-digicon-2022/thumb.png",
    subsection:"Live Streaming",
    path: "/content/topps-digicon-2022/",
    client: "Topps",
    projectName: "Digicon 2022",
    type: "content",
    legacyProject: false,
    description:
      "We partnered with Topps to produce Topp’s Digicon 2022 - an annual virtual celebration that puts a spotlight on the growing global collecting community. To engage with their fans and grow their collector community following, we created a visually unique experience featuring brand new in app content releases, watch parties, interviews with celebrities, personalities, athletes, artists & app events with notable brands like Disney, Marvel, etc. We were able to successfully stream via 2 different platforms expanding Topps reach from previous years. This resulted in higher viewership, engagement and social followers across both Twitch and Twitter where their audiences are.",
    gallery: [
      {
        url: "https://player.vimeo.com/video/1021911745?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
    ],
  },
  {
    thumbnailUrl: "/content/olly-in-the-girls-room/thumb.png",
    subsection:"Live Streaming",
    path: "/content/olly-in-the-girls-room/",
    client: "Olly",
    projectName: "#INTHEGIRLSROOM",
    type: "content",
    legacyProject: true,
    description:
      "The Olly brand came to us to create an intimate virtual press and media experience for their women’s wellness product launch. We leveraged one of our virtual event platforms with a ‘watch together” feature where attendees could see and speak to each other live, just as they would in real life and hold audio conversations with the six person panel. To animate the theme of “safe space stories,” we fully branded the platform and developed gamified interactivity with fill in the blank storytelling and wellness trivia. This engaging digital experience was supported with physical at home kits facilitating 2-way participation. The combination of strong creative branding, interactivity, dialogue, and engaging content streaming resulted in the audience staying on for the entire duration of the event with zero drop off.",
    gallery: [
      {
        url: "/content/olly-in-the-girls-room/olly_inthegirlsroom_1.gif",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/content/olly-in-the-girls-room/olly_inthegirlsroom_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/content/olly-in-the-girls-room/olly_inthegirlsroom_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/content/olly-in-the-girls-room/olly_inthegirlsroom_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/content/olly-in-the-girls-room/olly_inthegirlsroom_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/content/olly-in-the-girls-room/olly_inthegirlsroom_7.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/content/olly-in-the-girls-room/olly_inthegirlsroom_8.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/content/olly-in-the-girls-room/olly_inthegirlsroom_9.png",
        alt: "",
        sizeClass: "onethird",
      },
    ],
  },
  {
    thumbnailUrl: "/content/dove-crown-act/thumb.png",
    subsection:"Live Streaming",
    path: "/content/dove-crown-act/",
    client: "Dove",
    projectName: "Crown Act",
    type: "content",
    legacyProject: false,
    description:
      `<p>TH teamed up with Edelman and Dove to host a branded digital assembly, designed to bring awareness to race-based hair discrimination and the CROWN Act. This event featured the As Early As Five film, along with a keynote address from actress & author Tabitha Brown. Dove Self-Esteem Project Educator Dre Brown was then joined by a panel of individuals who shared their personal experiences as part of the CROWN movement. </p>

<p>The event concluded with a fireside chat, featuring a CROWN youth advocate and Eunique Jones Gibson. To encourage audience participation throughout the assembly, attendees were invited to show how they felt by using emojis. Live polling further engaged and educated the audience.</p>`,
gallery: [
  {
      url: "https://player.vimeo.com/video/1049324904?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
      type: "vimeo",
      alt: "",
      sizeClass: "full"
},
      {
        url:"/content/dove-crown-act/1.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url:"/content/dove-crown-act/2.png",
        alt: "",
        sizeClass: "half",
      }
    ],
  },
  {
    thumbnailUrl: "/content/sephora-night-in/thumb.png",
    subsection:"Live Streaming",
    path: "/content/sephora-night-in/",
    client: "Sephora",
    projectName: "Night In",
    type: "content",
    legacyProject: false,
    description:
      `<p>To connect #SephoraSquad influencers across the country, we designed and developed an engaging virtual touchpoint (complete with mailer kits) for the ‘22 Squad.</p>
      <p>This experience gave them an intimate education opportunity with brand founders of YTTP & GXVE, gaining in depth knowledge of the brands they know and love, and inspiring important career building discussion around community building via social media strategies.</p>`,
    gallery: [
      {
        url: "https://player.vimeo.com/video/1050161138?h=0a0194f501&background=1&loop=1&autoplay=1&muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full"
  },
      {
        url:"/content/sephora-night-in/1.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url:"/content/sephora-night-in/2.png",
        alt: "",
        sizeClass: "half",
      }
    ],
  },
  
  {
    thumbnailUrl: "/content/ralph-lauren-pink-pony/thumb.png",
    subsection:"Live Streaming",
    path: "/content/ralph-lauren-pink-pony/",
    client: "Ralph Lauren",
    projectName: "Pink Pony Virtual Floral Workshop",
    type: "content",
    legacyProject: true,
    description:
      "Ralph Lauren Fragrances partnered with us to develop their first ever digital event to drive awareness for their philanthropic work fighting breast cancer. Their objective was for the press and media to experience a new fragrance launch whose sales proceeds are 100% donated to the RL Pink Pony fund. We designed and executed a virtual floral arranging workshop with celebrity florist, Lewis Miller. Attendees were shipped florals that represented the top notes of the fragrance including the tools required to participate. Lewis Miller was streamed in live from his studio and led the workshop allowing attendees to virtually follow along and create their own arrangements. In addition to this interactive experience, we streamed in brand representatives to educate on the initiative and layered in campaign content to enrich the storytelling. We leveraged the event platforms interactive features to keep the audience engaged and concluded with a live audio Q&A.",
    gallery: [
      {
        url: "https://player.vimeo.com/video/612353719?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/content/ralph-lauren-pink-pony/rl_pink-pony_2.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/content/ralph-lauren-pink-pony/rl_pink-pony_3.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/content/ralph-lauren-pink-pony/rl_pink-pony_4.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/content/ralph-lauren-pink-pony/rl_pink-pony_5.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/content/ralph-lauren-pink-pony/rl_pink-pony_6.png",
        alt: "",
        sizeClass: "onethird",
      },
      {
        url: "/content/ralph-lauren-pink-pony/rl_pink-pony_7.png",
        alt: "",
        sizeClass: "twothird",
      },
      {
        url: "/content/ralph-lauren-pink-pony/rl_pink-pony_8.png",
        alt: "",
        sizeClass: "onethird",
      },
    ],
  },
  {
    thumbnailUrl: "/content/uno-championship/thumb.png",
    subsection:"Social Streaming",
    path: "/content/uno-championship/",
    client: "Uno",
    projectName: "Championship",
    type: "content",
    legacyProject: true,
    description:
      "We held the first ever UNO card game tournament for Mattel with a Covid-safe 150 person live event in Las Vegas to celebrate the brand's 50th anniversary. We strategized a hybrid approach to deliver the most accessible and inclusive experience for their global audience. The tournament hosted by eSports gaming celebrity Ninja, and was streamed live on the brand’s newly launched TikTok channel and viewed by over 460,000 fans globally.",
    gallery: [
      {
        url: "https://player.vimeo.com/video/655533034?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/content/uno-championship/uno-2.png",
        alt: "",
        subtitle: "In-Person",
        sizeClass: "half",
      },
      {
        url: "/content/uno-championship/uno-3.gif",
        alt: "",
        subtitle: "Virtual Extension on Tiktok",
        sizeClass: "half",
      },
    ],
  },
  {
    thumbnailUrl: "/content/misfits-paint-day/thumb.png",
    subsection:"Social Streaming",
    path: "/content/misfits-paint-day/",
    client: "Misfits",
    projectName: "Paint Day",
    type: "content",
    legacyProject: false,
    description:
      `<p>We partnered with Misfits to launch an in real life gaming competition, Splatoon IRL, for the brand’s biggest Minecraft influencers.</p>

<p>The game began with a white canvas and ended hours later with a paint soaked highly entertaining experience that set a new standard for offline play for online players.</p>

<p>Utilizing best in class live streaming techniques, we captured and streamed six hours of exciting and dynamic gameplay and received almost half a million views.</p>`,
gallery: [
  {
      url: "https://player.vimeo.com/video/731900005?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
      type: "vimeo",
      alt: "",
      sizeClass: "full"
},
      {
        url: "/content/misfits-paint-day/1.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/content/misfits-paint-day/2.png",
        alt: "",
        sizeClass: "half",
      }
    ],
  },
  {
    thumbnailUrl: "/content/kiss-simulcast-series/thumb.png",
    subsection:"Social Streaming",
    path: "/content/kiss-simulcast-series/",
    client: "Kiss",
    projectName: "Simulcast Series",
    type: "content",
    legacyProject: false,
    description:
      `<p>To successfully produce this multi-platform, social streaming experience, TH’s broadcast team leveraged technology innovation using a dedicated vertical streaming encoder which takes a high definition vertical video feed and streams it into Instagram. </p>

<p>Unlike other 3rd party Instagram Live stream services, TH streamed the feed from a control room directly into the Instagram app, avoiding any potential violations of terms of service. Using a cloud infrastructure built on Amazon's AWS, TH Experiential is able to handle the multicast of video streams to as many destinations as needed.</p>`,
    gallery: [
      {
        url: "https://player.vimeo.com/video/1057702441?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/content/kiss-simulcast-series/1.png",
        alt: "",
        sizeClass: "half",
      },
      {
        url: "/content/kiss-simulcast-series/2.png",
        alt: "",
        sizeClass: "half",
      }
    ],
  },
  {
    thumbnailUrl: "/content/lush-in-the-park/thumb.png",
    subsection:"Social Streaming",
    path: "/content/lush-in-the-park/",
    client: "Lush",
    projectName: "In the Park",
    type: "content",
    legacyProject: true,
    description:
      "TH teamed up with Lush Fresh Handmade Cosmetics to design and produce a unique moment to engage their community and new audiences around what they care about: insanely fresh ingredients and products. The brand’s behind-the-scenes chemists, a.k.a. Compounders, were brought to the forefront, demonstrating live how they make Lush products from citrus fruits, plants, grains and more. Oversized living walls covered in over twelve different plants including Asplenium, Japanese Birdsnest, Tri-color Oyster, Ferns and Ivy lead guests into the #LushInThePark interactive experience. Mounted touch screen monitors allowed people to select fragrance preferences and paired them with a Lush Compounder. At the Compounder Bar, guests’ product was handmade in front of them, a live storytelling moment on how truly “fresh” Lush’s products are.",
    gallery: [
      {
        url: "https://player.vimeo.com/video/655532771?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/content/lush-in-the-park/lush-2.png",
        alt: "",
        subtitle: "In-Person",
        sizeClass: "half",
      },
      {
        url: "/content/lush-in-the-park/lush-3.gif",
        alt: "",
        subtitle: "VIRTUAL EXTENSION ON YOUTUBE",
        sizeClass: "half",
      },
    ],
  },
  {
    thumbnailUrl: "/content/puma-workout-with-nycb/thumb.png",
    subsection:"Social Streaming",
    path: "/content/puma-workout-with-nycb/",
    client: "Puma",
    projectName: "Workout with NYCB",
    type: "content",
    legacyProject: true,
    description:
      "TH partnered with PUMA and New York City Ballet to create a one-of-a-kind workout experience for New Yorkers on September 23rd, 2017 in Lincoln Center Plaza in celebration of PUMA’s Velvet Rope Collection launch. PUMA Faster Trainers and New York City Ballet dancers choreographed and instructed a one hour, ballet inspired workout routine for 500 guests on the Plaza surrounding the iconic Revson Fountain. The event also featured exclusive PUMA and NYCB giveaways, a live DJ, and a VIP lounge for guests to enjoy prior to the workout. ",
    gallery: [
      {
        url: "https://player.vimeo.com/video/655532501?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1",
        type: "vimeo",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/content/puma-workout-with-nycb/puma-2.png",
        alt: "",
        subtitle: "In-Person",
        sizeClass: "half",
      },
      {
        url: "/content/puma-workout-with-nycb/puma-3.gif",
        alt: "",
        subtitle: "Virtual extension on Facebook",
        sizeClass: "half",
      },
    ],
  },
  {
    thumbnailUrl: "/content/ebay-toy-tokyo-event/thumb.png",
    subsection:"Social Streaming",
    path: "/content/ebay-toy-tokyo-event/",
    client: "Ebay",
    projectName: "Ebay x Toy Tokyo Event",
    type: "content",
    legacyProject: true,
    description:
      "For Comic Con, we partnered with eBay to produce a hybrid event introducing their NYCC exclusive merch + cards to the brand's fans and media. Hosted by celebrity DJ Skee, the event created the hype for attendees as they watched the host unbox an exclusive Pokémon booster box. The event was simulcast to three social streams including ebay and DJ Skee’s Twitter as well as eBay’s Instagram page. This virtual extension increased the audience reach to an additional 2,500 viewers.",
    gallery: [
      {
        url: "/content/ebay-toy-tokyo-event/ebay-1.png",
        alt: "",
        sizeClass: "full",
      },
      {
        url: "/content/ebay-toy-tokyo-event/ebay-2.png",
        alt: "",
        subtitle: "In-Person",
        sizeClass: "half",
      },
      {
        url: "/content/ebay-toy-tokyo-event/ebay-3.gif",
        alt: "",
        subtitle: "VIRTUAL EXTENSION ON TIKTOK",
        sizeClass: "half",
      },
    ],
  },
];

export default content;