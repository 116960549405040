import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Project(props) {
  const location = useLocation();
  const [proj, setProj] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadProjectData = async () => {
      try {
        let foundProj;
        
        if (props.type === 'activations') {
          const activationsData = (await import('./components/activations-data')).default;
          foundProj = activationsData.find((p) => p.path === location.pathname);
        } else if (props.type === 'digital') {
          const digitalData = (await import('./components/digital-data')).default;
          foundProj = digitalData.find((p) => p.path === location.pathname);
        } else if (props.type === 'content') {
          const contentData = (await import('./components/content-data')).default;
          foundProj = contentData.find((p) => p.path === location.pathname);
        }
        
        setProj(foundProj);
      } catch (error) {
        console.error('Error loading project data:', error);
      } finally {
        setLoading(false);
      }
    };

    loadProjectData();
  }, [location.pathname, props.type]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!proj) {
    return <div>Project not found</div>;
  }
  console.log(proj)
  let allPics = [...(proj.gallery || [])];
  let featured = allPics.length > 0 ? allPics.shift() : null;
  return (
    <>
      <div className="proj-wrap">
        <div className="proj-m-featured">
          {!featured || !featured.type || featured.type !== "vimeo" ? (
            <img 
              src={featured?.url} 
              {...(!proj.legacyProject && !featured?.url?.toLowerCase().endsWith('.gif') && !featured?.url?.toLowerCase().endsWith('.avif') && {
                srcSet: `${featured?.url.replace(/\.[^.]+$/, '@2x$&')} 2x, ${featured?.url.replace(/\.[^.]+$/, '@3x$&')} 3x`
              })}
              alt={`${proj.client} ${proj.projectName} featured`} 
            />
          ) : (
            <>
              <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                <iframe
                  src={featured.url}
                  frameborder="0"
                  allow="autoplay" 
                  webkit-playsinline 
                  playsinline
                  allowfullscreen
                  style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                  title={proj.projectName}
                ></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </>
          )}
        </div>
        <div className="proj-header-wrap">
          <div className="proj-header">
            <span className="proj-client">{proj.client}</span> <span className="proj-name">{proj.projectName}</span>
          </div>
        </div>
        <div className="proj-hero">
          <div className="proj-featured">
            {!featured || !featured.type || featured.type !== "vimeo" ? (
              <img 
              src={featured?.url} 
              {...(!proj.legacyProject && !featured?.url?.toLowerCase().endsWith('.gif') && !featured?.url?.toLowerCase().endsWith('.avif') && {
                srcSet: `${featured?.url.replace(/\.[^.]+$/, '@2x$&')} 2x, ${featured?.url.replace(/\.[^.]+$/, '@3x$&')} 3x`
              })}
              alt={`${proj.client} ${proj.projectName} featured`} 
            />
            ) : (
              <>
                <div class='embed-container' style={{
                  position: "relative",
                  paddingBottom: `calc( ${featured.customVideoHeight ? featured.customVideoHeight : 1080 } / ${featured.customVideoWidth ? featured.customVideoWidth : 1920 } * 100%)`,
                  overflow: "hidden",
                  maxWidth: "100%",
                  background: "black"
                }}>
                  <iframe
                    src={featured.url}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    playsInline
                    webkit-playsInline="true"
                    style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                    title={proj.projectName}
                  ></iframe>
                  </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
              </>
            )}
          </div>
          <div className="proj-text" dangerouslySetInnerHTML={{ __html: proj.description }} />
        </div>
        {proj.gallery && (
          <div className="gallery">
            {allPics.map((pic) => (
              <div className={`gallery-item ${pic.sizeClass}`}>
                <img 
                  src={pic.url} 
                  {...(!proj.legacyProject && !pic.url?.toLowerCase().endsWith('.gif')&& !pic.url?.toLowerCase().endsWith('.avif') && {
                    srcSet: `${pic.url.replace(/\.[^.]+$/, '@2x$&')} 2x, ${pic.url.replace(/\.[^.]+$/, '@3x$&')} 3x`
                  })}
                  alt={pic.alt} 
                />
                {
                  pic.subtitle && (
                  <span className={`gallery-subtitle`}>{pic.subtitle}</span>
                  )
                }
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default Project;
