import React from "react";
import { useNavigate } from "react-router-dom";
import "./css/intro.css";
import "./css/intro-new.css";
import Landing from "./LandingBackground";

const Intro = () => {
  const navigate = useNavigate();

  return (
    <div className="intro-wrap" onClick={() => navigate('we')} style={{ cursor: 'pointer' }}>
      <div className="intro-background">
        <Landing />
      </div>
    </div>
  );
};

export default Intro;
