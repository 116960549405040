import LinkGrid from "./components/LinkGrid";
import content from "./components/content-data";

function Content() {
  const subsections = [
    "Live Streaming",
    "Social Streaming"
  ];

  return <>
    {subsections.map(subsection => {
      const subsectionData = content.filter(item => item.subsection === subsection);
      if (subsectionData.length > 0) {
        return (
          <div className="subsection" key={subsection}>
            <h2 className="subsection-heading">{subsection}</h2>
            <LinkGrid gridData={subsectionData} />
          </div>
        );
      }
      return null;
    })}
  </>;
}

export default Content;
