function We() {
  return (
    <div className="we-wrap">
      <div className="embed-container">
        <iframe
          // src="https://player.vimeo.com/video/1043796528?h=e0c0e5d9c0&autoplay=1&loop=1&title=0&byline=0&portrait=0"
          src="https://player.vimeo.com/video/1043796528?h=ffb91ada95&background=1&loop=1&autoplay=1&muted=1"
          className="vimeo-iframe"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
      </div>
      <div className="who-we-are-section">
        <div className="we-text">
          <div className="heading who-we-are"><span style={{color: "#ED1E23"}}>WHO</span> WE ARE</div>
          <p>
            <strong>
              Experiential is where your audience stops to interact with your brand directly, with the potential to go
              from consideration to conversion in minutes.
            </strong>
            <span>
            TH Experiential has been developing and evolving this form of marketing since 2006, dynamically connecting
            hundreds of brands with their press, influencers and consumers over thousands of activations.The experiences
            we create engage across all channels, reaching your audiences not just at live events but on social,
            ecommerce, through influencer marketing and earned media. We leverage marketing and technology innovation to make these experiences more impactful, intuitive, and
            personalized to drive participation. Experiential is measurable marketing, where at TH, we leverage AI powered
            analytics to show how your investment increases brand sentiment, NPS, purchase intent and conversion.
            </span>
          </p>
        </div>
        <div className="we-image">
          <picture className="we-logos">
            <source
              srcSet="/we/we_logos.png"
            />
            <img
              src="/we/we_logos.png"
              alt="TH Experiential, a GES company"
              className="we-logos"
            />
          </picture>
        </div>
      </div>

      <div className="we-text">
        <div className="heading what-we-do"><span style={{color: "#ED1E23"}}>WHAT</span> WE DO</div>
        <p>We have fifteen tools and solutions to engage your audiences across all channels.</p>
        
      </div>
      <div className="icon-grid">
          <div className="icon-grid-item">
            <img
              srcSet="/we/we_tools_1.avif, /we/we_tools_1@2x.avif 2x, /we/we_tools_1@3x.avif 3x"
              src="/we/we_tools_1.avif"
              alt="POP UPS"
            />
            <span>Pop Ups</span>
          </div>
          <div className="icon-grid-item">
            <img
              srcSet="/we/we_tools_2.avif, /we/we_tools_2@2x.avif 2x, /we/we_tools_2@3x.avif 3x"
              src="/we/we_tools_2.avif"
              alt="MOBILE TOURS"
            />
            <span>Mobile Tours</span>
          </div>
          <div className="icon-grid-item">
            <img
              srcSet="/we/we_tools_3.avif, /we/we_tools_3@2x.avif 2x, /we/we_tools_3@3x.avif 3x"
              src="/we/we_tools_3.avif"
              alt="PRESS/INFLUENCER EVENTS"
            />
            <span>Press/Influencer Events</span>
          </div>
          <div className="icon-grid-item">
            <img
              srcSet="/we/we_tools_4.avif, /we/we_tools_4@2x.avif 2x, /we/we_tools_4@3x.avif 3x"
              src="/we/we_tools_4.avif"
              alt="OUTDOOR ACTIVATIONS"
            />
            <span>Outdoor Activations</span>
          </div>
          <div className="icon-grid-item">
            <img
              srcSet="/we/we_tools_5.avif, /we/we_tools_5@2x.avif 2x, /we/we_tools_5@3x.avif 3x"
              src="/we/we_tools_5.avif"
              alt="PERFORMANCES"
            />
            <span>Performances</span>
          </div>
          <div className="icon-grid-item">
            <img
              srcSet="/we/we_tools_6.avif, /we/we_tools_6@2x.avif 2x, /we/we_tools_6@3x.avif 3x"
              src="/we/we_tools_6.avif"
              alt="TRADE SHOWS"
            />
            <span>Trade Shows</span>
          </div>
          <div className="icon-grid-item">
            <img
              srcSet="/we/we_tools_7.avif, /we/we_tools_7@2x.avif 2x, /we/we_tools_7@3x.avif 3x"
              src="/we/we_tools_7.avif"
              alt="CONFERENCES"
            />
            <span>Conferences</span>
          </div>
          <div className="icon-grid-item">
            <img
              srcSet="/we/we_tools_8.avif, /we/we_tools_8@2x.avif 2x, /we/we_tools_8@3x.avif 3x"
              src="/we/we_tools_8.avif"
              alt="STUNTS"
            />
            <span>Stunts</span>
          </div>
          <div className="icon-grid-item">
            <img
              srcSet="/we/we_tools_9.avif, /we/we_tools_9@2x.avif 2x, /we/we_tools_9@3x.avif 3x"
              src="/we/we_tools_9.avif"
              alt="SAMPLING"
            />
            <span>Sampling</span>
          </div>
          <div className="icon-grid-item">
            <img
              srcSet="/we/we_tools_10.avif, /we/we_tools_10@2x.avif 2x, /we/we_tools_10@3x.avif 3x"
              src="/we/we_tools_10.avif"
              alt="MAILERS"
            />
            <span>Mailers</span>
          </div>
          <div className="icon-grid-item">
            <img
              srcSet="/we/we_tools_11.avif, /we/we_tools_11@2x.avif 2x, /we/we_tools_11@3x.avif 3x"
              src="/we/we_tools_11.avif"
              alt="GAME DEVELOPMENT"
            />
            <span>Game Development</span>
          </div>
          <div className="icon-grid-item">
            <img
              srcSet="/we/we_tools_12.avif, /we/we_tools_12@2x.avif 2x, /we/we_tools_12@3x.avif 3x"
              src="/we/we_tools_12.avif"
              alt="AR/VR/HOLOGRAMS"
            />
            <span>AR/VR/Holograms</span>
          </div>
          <div className="icon-grid-item">
            <img
              srcSet="/we/we_tools_13.avif, /we/we_tools_13@2x.avif 2x, /we/we_tools_13@3x.avif 3x"
              src="/we/we_tools_13.avif"
              alt="MICROSITES"
            />
            <span>Microsites</span>
          </div>
          <div className="icon-grid-item">
            <img
              srcSet="/we/we_tools_14.avif, /we/we_tools_14@2x.avif 2x, /we/we_tools_14@3x.avif 3x"
              src="/we/we_tools_14.avif"
              alt="CONTENT STREAMING AND PRODUCTION"
            />
            <span>Content Streaming and Production</span>
          </div>
          <div className="icon-grid-item">
            <img
              srcSet="/we/we_tools_15.avif, /we/we_tools_15@2x.avif 2x, /we/we_tools_15@3x.avif 3x"
              src="/we/we_tools_15.avif"
              alt="AI POWERED EXPERIENCES"
            />
            <span>AI Powered Experiences</span>
          </div>
        </div>
      <div className="we-text two-column">
        <div className="column">
          <div className="heading-no-pad how-we-work"><span style={{color: "#ED1E23"}}>HOW</span> WE WORK</div>
          <p>
            We are not just an agency, we are your strategic partner, an extension of your team. We are committed to
            exceptional service and exceeding expectations. Our trusted teams and expert process ensure:
          </p>
          <ul>
            <li>The most creative expression possible</li>
            <li>Attention to detail and all logistics for a seamless and flawless execution</li>
            <li>Activations are staffed by properly trained top talent</li>
            <li>Delivered on time and in budget</li>
          </ul>
        </div>
        <div className="column">
          <img
            src="/we/we_howwework.gif"
            alt="How We Work"
          />
        </div>
      </div>
    </div>
  );
}

export default We;
