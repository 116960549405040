import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import svgLogo from "../assets/thexperiential-logo-wide.svg";
import svgLogoMobile from "../assets/thexperiential-logo-mobile.svg";
import contentData from "./content-data";

export default function Header() {
  const location = useLocation();
  const proj = contentData.find((p) => p.path === location.pathname);
  const isDigital = proj && proj.type === "digital";
  const isActivations = proj && proj.type === "activations";
  const isContent = proj && proj.type === "content";

  return (
    <header>
      <div className="header-logo">
        <NavLink to="/">
          <img src={svgLogo} alt="TH Experiential logo" className="desktop-logo" />
          <img src={svgLogoMobile} alt="TH Experiential logo" className="mobile-logo" />
        </NavLink>
      </div>
      <div className="header-nav">
        <span>
          <NavLink to="/we">WE</NavLink>
        </span>
        <span>
          <NavLink 
            to="/activations"
            className={({ isActive }) => isActive || isActivations ? "active" : ""}
          >
            Activations
          </NavLink>
        </span>
        <span>
          <NavLink 
            to="/digital"
            className={({ isActive }) => isActive || isDigital ? "active" : ""}
          >
            Digital
          </NavLink>
        </span>
        <span>
          <NavLink 
            to="/content"
            className={({ isActive }) => isActive || isContent ? "active" : ""}
          >
            Content
          </NavLink>
        </span>
      </div>
    </header>
  );
}
